import { WritableDraft } from "immer/dist/internal";
import cloneDeep from "lodash/cloneDeep";
import isUndefined from "lodash/isUndefined";
import { Flow, Step, StepCaseEntry, Steps } from "model";
import { Redoable } from "store/utils/redoableSliceFactory";
import { updateEntityWithDraft } from "store/utils/redoableSliceMutators";
import YAML from "yaml";

import { serializeFlow } from "../../services/flows";

type UpdateFunction = (step: Step) => void;

export const modifyNestedStep = (
  steps: Step[],
  stepId: string,
  updateFn: UpdateFunction
): void => {
  for (let i = 0; i <= steps.length; i++) {
    const entity = steps[i];

    if (entity && entity.stepType === Steps.SWITCH) {
      if (entity.id === stepId) {
        updateFn(entity);
        return;
      }

      if (entity.cases) {
        entity.cases.forEach((cs) => {
          const caseSteps = cs.steps as Step[];
          modifyNestedStep(caseSteps, stepId, updateFn);
        });
      }

      if (entity.defaultCase) {
        modifyNestedStep(entity.defaultCase, stepId, updateFn);
      }
    }
  }
};

type ModifyFn = (n: Step[], index: number) => Step[];

const modifySwitchStep = (
  parent: Step,
  stepId: string,
  modifyFn: ModifyFn
): void => {
  if (parent.defaultCase) {
    const defaultCases = parent.defaultCase as Step[];
    defaultCases.forEach((dc) => {
      if (dc.stepType === Steps.SWITCH) modifySwitchStep(dc, stepId, modifyFn);
    });

    const updateIdx = defaultCases.findIndex((dc) => dc.id === stepId);

    if (updateIdx !== -1) {
      parent.defaultCase = modifyFn(defaultCases, updateIdx);
      return;
    }
  }

  if (parent.cases) {
    const stepCases = parent.cases;
    stepCases.forEach((c) => {
      if (c.steps) {
        const caseSteps = c.steps as Step[];
        caseSteps.forEach((cs) => {
          if (cs.stepType === Steps.SWITCH)
            modifySwitchStep(cs, stepId, modifyFn);
        });
        const updateIdx = caseSteps.findIndex((dc) => dc.id === stepId);

        if (updateIdx !== -1) {
          c.steps = modifyFn(caseSteps, updateIdx);
          return;
        }
      }
    });
  }
};

export const updateNestedStep = (switchStep: Step, newData: Step): void => {
  const updateFn = (steps: Step[], updateIdx: number) => {
    const newSteps = [...steps];
    newSteps.splice(updateIdx, 1, newData);

    return newSteps;
  };

  modifySwitchStep(switchStep, newData.id, updateFn);
};

export const deleteNestedStep = (switchStep: Step, stepId: string): void => {
  const deleteFn = (steps: Step[], updateIdx: number) => {
    const newSteps = [...steps];
    newSteps.splice(updateIdx, 1);

    return newSteps;
  };

  modifySwitchStep(switchStep, stepId, deleteFn);
};

export const findNestedStep = (
  steps: Step[],
  selectedStepId: string
): Step | undefined => {
  if (!Array.isArray(steps)) return;

  for (const entity of steps) {
    if (entity.condition !== undefined) {
      const step: Step | undefined = findNestedStep(
        entity.steps as Step[],
        selectedStepId
      );
      if (step && step.id === selectedStepId) return step;
    }

    if (entity.cases) {
      const step: Step | undefined = findNestedStep(
        // eslint-disable-next-line
        // @ts-expect-error: nested cases typing
        entity.cases,
        selectedStepId
      );
      if (step && step.id === selectedStepId) return step;
    }

    if (entity.defaultCase) {
      const step: Step | undefined = findNestedStep(
        entity.defaultCase as Step[],
        selectedStepId
      );
      if (step && step.id === selectedStepId) return step;
    }

    if (entity.id === selectedStepId) return entity;
  }
};

export const getModifiableStepIndex = (steps: Step[], stepId: string): number =>
  steps.findIndex((entity) => {
    if (entity.stepType === Steps.SWITCH) {
      if (entity.id === stepId) return true;

      // eslint-disable-next-line
      // @ts-expect-error: nested cases typing
      const casesStep = findNestedStep(entity.cases, stepId);
      if (casesStep) return true;

      const defaultStep = findNestedStep(entity.defaultCase as Step[], stepId);
      if (defaultStep) return true;
    }

    return entity.id === stepId;
  });

export const updateFlowEntity = (
  state: WritableDraft<Redoable<Flow>>[],
  newEntityState: Flow
): void => {
  const draftValue = YAML.stringify(serializeFlow(newEntityState));

  updateEntityWithDraft(state, newEntityState, draftValue);
};

export const removeStepBase = (
  existingFlow: Flow,
  stepId: string
): Flow | undefined => {
  const removalIdx = getModifiableStepIndex(existingFlow.steps, stepId);

  if (removalIdx !== -1) {
    const local = cloneDeep(existingFlow);
    const { steps } = local;

    const isNestedStep =
      steps[removalIdx].stepType === Steps.SWITCH &&
      steps[removalIdx].id !== stepId;

    if (isNestedStep) {
      const switchStep = steps[removalIdx];
      deleteNestedStep(switchStep, stepId);

      const newEntityState = { ...existingFlow, ...local };
      return newEntityState;
    } else {
      const newSteps = [...steps];
      newSteps.splice(removalIdx, 1);

      const newEntityState = { ...existingFlow, steps: newSteps };
      return newEntityState;
    }
  }
};

type AddStepBaseArgs = {
  existingFlow: Flow;
  step: Step;
  toIdx?: number;
  parentId?: string;
  parentPath?: string;
  parentPathIdx?: number;
};

export const addStepBase = ({
  existingFlow,
  step,
  toIdx,
  parentId,
  parentPath,
  parentPathIdx,
}: AddStepBaseArgs): Flow | undefined => {
  if (parentId && parentPath) {
    const local = cloneDeep(existingFlow);
    const { steps } = local;

    const existingParentStep = findNestedStep(steps, parentId);

    if (existingParentStep) {
      const stepsArray = (existingParentStep[parentPath] as Step[]) ?? [];
      const newSteps = [...stepsArray];

      if (parentPathIdx !== undefined) {
        const conditionStepsArray = newSteps[parentPathIdx].steps as Step[];
        const conditionSteps = [...conditionStepsArray];
        const start = isUndefined(toIdx) ? conditionSteps.length : toIdx;

        conditionSteps.splice(start, 0, step);

        newSteps[parentPathIdx].steps = [...conditionSteps];
      } else {
        const start = isUndefined(toIdx) ? stepsArray.length : toIdx;
        newSteps.splice(start, 0, step);

        existingParentStep[parentPath] = newSteps;
      }

      const newEntityState = { ...existingFlow, ...local };
      return newEntityState;
    }
  } else {
    const { steps } = existingFlow;
    const existingStep = steps.find((st) => st.id === step.id);

    if (!existingStep) {
      const newSteps = [...steps];
      const start = isUndefined(toIdx) ? steps.length : toIdx;
      newSteps.splice(start, 0, step);
      const newEntityState = { ...existingFlow, steps: newSteps };
      return newEntityState;
    }
  }
};

type DeleteSwitchStepCaseBaseArgs = {
  existingFlow: Flow;
  index: number;
  stepId: string;
};

type DeleteSwitchStepCaseBaseReturn = {
  newRemoveState: Flow;
  removedEntity?: StepCaseEntry;
};

export const deleteSwitchStepCaseBase = ({
  existingFlow,
  index,
  stepId,
}: DeleteSwitchStepCaseBaseArgs): DeleteSwitchStepCaseBaseReturn => {
  const local = cloneDeep(existingFlow);
  const { steps } = local;
  let newEntityState: Flow;
  let removedStepCaseEntry: StepCaseEntry | undefined;

  const deleteCaseFn = (entity: Step) => {
    if (Array.isArray(entity.cases)) {
      const newCases = [...entity.cases];
      const removedEntities = newCases.splice(index, 1);
      removedStepCaseEntry = removedEntities.pop();

      entity.cases = newCases;
    }

    newEntityState = { ...existingFlow, ...local };
  };

  modifyNestedStep(steps, stepId, deleteCaseFn);

  return {
    // @ts-expect-error: typescript is lying
    newRemoveState: newEntityState,
    removedEntity: removedStepCaseEntry,
  };
};

type AddSwitchStepCaseBaseArgs = {
  existingFlow: Flow;
  stepId: string;
  condition?: string;
  caseEntry?: StepCaseEntry;
  toIdx?: number;
};

export const addSwitchStepCaseBase = ({
  existingFlow,
  stepId,
  condition = "",
  caseEntry,
  toIdx,
}: AddSwitchStepCaseBaseArgs): Flow => {
  const local = cloneDeep(existingFlow);
  const { steps } = local;
  let newEntityState: Flow;

  const entityToAdd = caseEntry ?? {
    condition,
    steps: [],
  };

  const addCaseFn = (entity: Step) => {
    if (Array.isArray(entity.cases)) {
      const newSteps = [...entity.cases];
      const start = isUndefined(toIdx) ? entity.cases.length : toIdx;
      newSteps.splice(start, 0, entityToAdd);

      entity.cases = newSteps;
    } else {
      entity.cases = [entityToAdd];
    }

    newEntityState = { ...existingFlow, ...local };
  };

  modifyNestedStep(steps, stepId, addCaseFn);

  // @ts-expect-error: typescript is lying
  return newEntityState;
};
