import { createReducer } from "@reduxjs/toolkit";

import { initializeDesignerAction } from "../designer/actions";
import { setUnsavedEntity } from "../designer/caseReducers/utils/setUnsavedEntity";
import { toggleExpandedFolder } from "../designer/caseReducers/utils/toggleExpandedFolder";
import {
  deleteFlowAction,
  openFlowAction,
  storeFlowFolderAction,
} from "../flows/actions";
import {
  add as addFlow,
  remove as removeFlow,
  removeAll as removeAllFlows,
} from "../flows/reducers";
import { storeSchemasFolderAction } from "../schemas/actions";
import {
  hideEntityFileFromWorkspaceAction,
  showEntityFileInWorkspaceAction,
  updateWorkspaceFolderAction,
} from "../workspaces/actions";
import {
  closeFlowTabAction,
  reorderFlowTabAction,
  selectFlowTabAction,
} from "../workspaces/tabActions";
import { RootState } from "..";

import { addUnsavedEntityCaseReducers } from "./utils/addUnsavedEntityCaseReducers";
import { finishInitializeLoading } from "./mutators.designer";
import {
  closeAllTabs,
  closeTab,
  openTab,
  reorderOpenTabs,
} from "./mutators.tabs";
import {
  hideFile,
  showFile,
  updateWorkspaceFolder,
} from "./mutators.workspace";

const reducer = createReducer<RootState>({} as RootState, (builder) => {
  addUnsavedEntityCaseReducers(builder);

  builder
    .addCase(updateWorkspaceFolderAction.fulfilled, (state, action) => {
      updateWorkspaceFolder(state, action.payload);
    })
    .addCase(reorderFlowTabAction, (state, action) => {
      reorderOpenTabs(state, action.payload, "flow");
    })
    .addCase(selectFlowTabAction, (state, action) => {
      openTab(state, action.payload, "flow");
    })
    .addCase(addFlow, (state, action) => {
      openTab(state, action.payload.id, "flow");
    })
    .addCase(openFlowAction.fulfilled, (state, action) => {
      openTab(state, action.payload.id, "flow");
    })
    .addCase(closeFlowTabAction, (state, action) => {
      closeTab(state, action.payload, "flow");
    })
    .addCase(removeFlow, (state, action) => {
      closeTab(state, action.payload, "flow");
      setUnsavedEntity(state.designer, "flow", null);
    })
    .addCase(removeAllFlows, (state, action) => {
      closeAllTabs(state, "flow", action.payload);
    })
    .addCase(deleteFlowAction.fulfilled, (state, action) => {
      closeTab(state, action.meta.arg, "flow");
    })
    .addCase(hideEntityFileFromWorkspaceAction.fulfilled, (state, action) => {
      hideFile(state, action.payload.objectPath, action.meta.arg.entityFolder);
    })
    .addCase(showEntityFileInWorkspaceAction, (state, action) => {
      showFile(state, action.payload, action.payload.entityFolder);
    })
    .addCase(initializeDesignerAction.pending, (state) => {
      state.designer.isDesignerLoading = true;
    })
    .addCase(initializeDesignerAction.rejected, (state) => {
      finishInitializeLoading(state);
    })
    .addCase(initializeDesignerAction.fulfilled, (state) => {
      finishInitializeLoading(state);
    })
    .addCase(storeFlowFolderAction.fulfilled, (state, action) => {
      toggleExpandedFolder(state.designer, "flow", action.payload);
    })
    .addCase(storeSchemasFolderAction.fulfilled, (state, action) => {
      toggleExpandedFolder(state.designer, "schemas", action.payload);
    });
});

export default reducer;
