import { FC, useCallback } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import ButtonComponent from "@sapiens-digital/ace-designer-app/app/components/ButtonComponent";
import { getKeycloakAuthUrl } from "login/utils";

export const ACCESS_DENIED_MESSAGE =
  "Unauthorized, you do not have permission to use ACE Designer.";

const AccessDeniedPopup: FC = () => {
  const logout = useCallback((): void => {
    window.location.href = getKeycloakAuthUrl() + "/logout";
  }, []);
  return (
    <Dialog open={true}>
      <DialogContent>{ACCESS_DENIED_MESSAGE}</DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <ButtonComponent
          variant="contained"
          onClick={logout}
          data-testid="access-denied-logout-btn"
        >
          Logout to continue
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export { AccessDeniedPopup };
