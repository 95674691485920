import React from "react";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";

import { SettingsManager } from "../../services/settingsManager";
import { downloadFS } from "../../services/workspace/downloadFs";

import { downloadMenuButtonStyles } from "./LogExporter";

export const IndexedDBExporter: React.FC<{
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsExporting }) => (
  <Button
    component="div"
    sx={downloadMenuButtonStyles}
    onClick={async () => {
      setIsExporting(true);
      await exportIndexedDB(SettingsManager.getWorkspacesLocation());
      setIsExporting(false);
    }}
  >
    Files
  </Button>
);

async function exportIndexedDB(rootDir: string) {
  const blob = new Blob([await downloadFS(rootDir)], {
    type: "application/zip",
  });
  saveAs(blob, `designer-fs ${new Date()}.zip`);
}
