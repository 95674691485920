export const btoaFn = (data: string): string => {
  try {
    return btoa(data);
  } catch (e) {
    return Buffer.from(data, "binary").toString("base64");
  }
};

export const createGitCredentials = (
  token: string
): { [x: string]: string } => ({
  Authorization: `Basic ${btoaFn("-n:" + token)}`,
});

export const createGitCredentialsForGit = (
  token: string,
  username?: string
): Record<string, unknown> => {
  const credObj: Record<string, unknown> = {};
  if (username) {
    credObj["onAuth"] = () => ({
      username: username,
      password: token,
    });
  } else {
    credObj["headers"] = createGitCredentials(token);
  }

  return credObj;
};
