import { useCallback, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "@sapiens-digital/ace-designer-app/app/store";
import { selectIsDesignerLoading } from "@sapiens-digital/ace-designer-app/app/store/designer/selectors";
import {
  removeGitAuthorFromKeycloakAction,
  saveGitAuthorFromKeycloakAction,
} from "@sapiens-digital/ace-designer-app/app/store/settings/actions";

import {
  getEmailFromToken,
  getNameFromToken,
  getTimeRemainingToExpire,
} from "./JWTTokenUtils";
import {
  clearTokenFromLocalStore,
  getTokenFromLocalStorage,
  isKeycloakAuthenticationEnabled,
} from "./utils";

/**
 * Hook to get the Auth token from local storage and listen for token changes
 */
export const useToken = (): {
  token: string | null;
  clearToken: () => void;
} => {
  const [token, setToken] = useState(getTokenFromLocalStorage());
  const [expTimeoutRef, setExpTimeoutRef] =
    useState<ReturnType<typeof setTimeout>>();
  const isDesignerLoading = useAppSelector(selectIsDesignerLoading);
  const dispatch = useAppDispatch();

  const clearToken = useCallback(() => {
    setToken(null);
    setExpTimeoutRef(undefined);
    dispatch(removeGitAuthorFromKeycloakAction());
    clearTokenFromLocalStore();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (expTimeoutRef) {
        clearTimeout(expTimeoutRef);
      }
    };
  }, [expTimeoutRef]);

  const registerTimeoutForTokenExp = useCallback(
    (token: string) => {
      if (isKeycloakAuthenticationEnabled() && !expTimeoutRef) {
        const ttl = getTimeRemainingToExpire(token);

        if (!ttl || ttl < 0) {
          return clearToken();
        }

        const ref = setTimeout(clearToken, ttl);
        setExpTimeoutRef(ref);
      }
    },
    [clearToken, expTimeoutRef]
  );

  useEffect(() => {
    if (!token) {
      return;
    }

    registerTimeoutForTokenExp(token);

    const email = getEmailFromToken(token);
    const name = getNameFromToken(token);
    if (!isDesignerLoading) {
      dispatch(saveGitAuthorFromKeycloakAction({ name, email }));
    }
  }, [dispatch, isDesignerLoading, registerTimeoutForTokenExp, token]);

  const onStorageChange = useCallback(
    (event: StorageEvent): void => {
      if (event.key !== "Authorization") {
        return;
      }

      if (!event.newValue) {
        return clearToken();
      }

      setToken(event.newValue);
    },
    [clearToken]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [onStorageChange]);

  return { token, clearToken };
};
