import {
  FC,
  lazy,
  SetStateAction,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { OpenAPIV3 } from "openapi-types";

import { trimUrl } from "../apis-list/APIsList";

import { HideAllErrorsPlugin } from "./HideAllErrorsPlugin";

import "swagger-ui-react/swagger-ui.css";

const SwaggerUI = lazy(() => import("swagger-ui-react"));

export const API_DEFINITION_PATH = "/api-definition";

const getData = async (
  defUrl: string,
  setLoadError: React.Dispatch<SetStateAction<string>>
) => {
  try {
    const response = await fetch(defUrl);
    if (!response.ok) throw new Error(response.statusText);

    const data = await response.json();
    return data;
  } catch (err) {
    setLoadError(ERROR_FAILED_TO_LOAD);
    return undefined;
  }
};

const addDefaultServerToDefinition = (
  def: Partial<OpenAPIV3.Document>,
  defUrl: string | null
) => {
  if (!def.servers && defUrl) {
    const defaultServerUrl = trimUrl(defUrl);
    def.servers = [
      {
        url: defaultServerUrl,
      },
    ];
  }
};

export const ERROR_NO_URL = "Can't load specification: No definition url given";
export const ERROR_FAILED_TO_LOAD = "Couldn't load specification";

const APIDefinition: FC = () => {
  const [searchParams] = useSearchParams();
  const defUrl = searchParams.get("url");
  const [loadError, setLoadError] = useState("");
  const [spec, setSpec] = useState<Partial<OpenAPIV3.Document> | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (!defUrl) {
        setLoadError(ERROR_NO_URL);
        return;
      }

      const data = await getData(defUrl, setLoadError);

      if (data) {
        addDefaultServerToDefinition(data, defUrl);
        setSpec(data);
      }
    }
    fetchData();
  }, [defUrl]);

  const swaggerUi = useMemo(() => {
    if (!spec) {
      return (
        <CircularProgress
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      );
    }

    return (
      <SwaggerUI spec={spec} tryItOutEnabled plugins={[HideAllErrorsPlugin]} />
    );
  }, [spec]);

  if (loadError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {loadError}
      </Box>
    );
  }

  return (
    <Suspense
      fallback={
        <CircularProgress
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      }
    >
      {swaggerUi}
    </Suspense>
  );
};

export { APIDefinition };
