import { PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { Steps } from "model";
import YAML from "yaml";

import { Flow } from "../../model/flow";
import { Step } from "../../model/step";
import { serializeFlow } from "../../services/flows";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import {
  redoEntity,
  undoEntity,
  updateEntityWithDraft,
} from "../utils/redoableSliceMutators";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { closeFlowTabAction } from "../workspaces/tabActions";

import {
  deleteFlowAction,
  deleteFlowFolderAction,
  generateDataForInputSchemaAction,
  loadFlowAction,
  openFlowAction,
  overwriteFlowFolderAction,
  refreshFlowsAction,
  storeFlowAction,
} from "./actions";
import {
  addStepBase,
  addSwitchStepCaseBase,
  deleteSwitchStepCaseBase,
  getModifiableStepIndex,
  modifyNestedStep,
  removeStepBase,
  updateFlowEntity,
  updateNestedStep,
} from "./reducerUtilities";
import { name } from "./sliceName";

const flowsSlice = createRedoableSlice({
  name,
  reducers: {
    addStep(
      state,
      action: PayloadAction<{
        flowId: string;
        step: Step;
        toIdx?: number;
        parentId?: string;
        parentPath?: string;
        parentPathIdx?: number;
      }>
    ) {
      const { flowId, toIdx, step, parentId, parentPath, parentPathIdx } =
        action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (!existingFlow) return;

      const newState = addStepBase({
        existingFlow: existingFlow.present,
        toIdx,
        step,
        parentId,
        parentPath,
        parentPathIdx,
      });

      if (newState) {
        updateFlowEntity(state, newState);
      }
    },
    updateStep(state, action: PayloadAction<{ flowId: string; step: Step }>) {
      const { flowId, step } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { present } = existingFlow;
        const updateIdx = getModifiableStepIndex(present.steps, step.id);

        if (updateIdx !== -1) {
          const local = cloneDeep(existingFlow.present);
          const { steps } = local;

          const isNestedStep =
            steps[updateIdx].stepType === Steps.SWITCH &&
            steps[updateIdx].id !== step.id;

          if (isNestedStep) {
            const switchStep = steps[updateIdx];

            updateNestedStep(switchStep, step);

            const newEntityState = { ...existingFlow.present, ...local };
            updateFlowEntity(state, newEntityState);
          } else {
            const newSteps = [...steps];
            newSteps.splice(updateIdx, 1, step);

            const newEntityState = { ...existingFlow.present, steps: newSteps };
            updateFlowEntity(state, newEntityState);
          }
        }
      }
    },
    removeStep(
      state,
      action: PayloadAction<{ flowId: string; stepId: string }>
    ) {
      const { stepId, flowId } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const newState = removeStepBase(existingFlow.present, stepId);

        if (newState) {
          updateFlowEntity(state, newState);
        }
      }
    },
    moveStep(
      state,
      action: PayloadAction<{
        flowId: string;
        step: Step;
        toIdx?: number;
        parentId?: string;
        parentPath?: string;
        parentPathIdx?: number;
      }>
    ) {
      const { flowId, toIdx, step, parentId, parentPath, parentPathIdx } =
        action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (!existingFlow) return;

      const newRemoveState = removeStepBase(existingFlow.present, step.id);

      if (newRemoveState) {
        const newAddState = addStepBase({
          existingFlow: newRemoveState,
          toIdx,
          step,
          parentId,
          parentPath,
          parentPathIdx,
        });

        if (newAddState) {
          updateFlowEntity(state, newAddState);
        }
      }
    },
    updateWithFlowDraft(state, action: PayloadAction<Flow>) {
      const flow = action.payload;
      const draftValue = YAML.stringify(serializeFlow(flow));

      return updateEntityWithDraft(state, flow, draftValue);
    },
    redoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      redoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
    undoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      undoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
    updateSwitchStepCaseCondition(
      state,
      action: PayloadAction<{
        flowId: string;
        index: number;
        stepId: string;
        condition: string;
      }>
    ) {
      const { flowId, index, stepId, condition } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const local = cloneDeep(existingFlow.present);
        const { steps } = local;

        const updateCaseConditionFn = (entity: Step) => {
          const workableEntries = Array.isArray(entity.cases)
            ? entity.cases
            : [];

          const updatableCaseEntry = workableEntries[index];

          if (updatableCaseEntry) {
            updatableCaseEntry.condition = condition;
          }

          const newEntityState = { ...existingFlow.present, ...local };
          updateFlowEntity(state, newEntityState);
        };

        modifyNestedStep(steps, stepId, updateCaseConditionFn);
      }
    },
    addSwitchStepCase(
      state,
      action: PayloadAction<{
        flowId: string;
        stepId: string;
        condition: string;
      }>
    ) {
      const { flowId, stepId, condition } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const newState = addSwitchStepCaseBase({
          existingFlow: existingFlow.present,
          stepId,
          condition,
        });

        if (newState) {
          updateFlowEntity(state, newState);
        }
      }
    },
    deleteSwitchStepCase(
      state,
      action: PayloadAction<{
        flowId: string;
        index: number;
        stepId: string;
      }>
    ) {
      const { flowId, index, stepId } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { newRemoveState } = deleteSwitchStepCaseBase({
          existingFlow: existingFlow.present,
          index,
          stepId,
        });

        if (newRemoveState) {
          updateFlowEntity(state, newRemoveState);
        }
      }
    },
    moveSwitchStepCase(
      state,
      action: PayloadAction<{
        flowId: string;
        parentId: string;
        draggableParentId: string;
        toIdx: number;
        fromIdx: number;
      }>
    ) {
      const { flowId, parentId, draggableParentId, toIdx, fromIdx } =
        action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (!existingFlow) return;

      const { newRemoveState, removedEntity } = deleteSwitchStepCaseBase({
        existingFlow: existingFlow.present,
        index: fromIdx,
        stepId: draggableParentId,
      });

      if (newRemoveState) {
        const newAddState = addSwitchStepCaseBase({
          existingFlow: newRemoveState,
          stepId: parentId,
          caseEntry: removedEntity,
          toIdx,
        });

        if (newAddState) {
          updateFlowEntity(state, newAddState);
        }
      }
    },
  },
  predefinedThunks: {
    openEntityAction: openFlowAction,
    closeEntityTabAction: closeFlowTabAction,
    deleteEntityAction: deleteFlowAction,
    deleteFolderAction: deleteFlowFolderAction,
    overwriteFolderAction: overwriteFlowFolderAction,
    loadEntityAction: loadFlowAction,
    storeEntityAction: storeFlowAction,
    refreshEntitiesAction: refreshFlowsAction,
  },
  extraReducers: (builder) => {
    builder.addCase(
      generateDataForInputSchemaAction.fulfilled,
      (state, action) => {
        const flow = action.payload;
        const draftValue = YAML.stringify(serializeFlow(flow));

        return updateEntityWithDraft(state, flow, draftValue);
      }
    );
  },
});

export const {
  update,
  updateDraft,
  updateWithFlowDraft,
  updateOmitHistory,
  add,
  addTargetFolder,
  remove,
  redoWithDraft,
  undoWithDraft,
  addStep,
  updateStep,
  removeStep,
  moveStep,
  markDraftAsValid,
  markDraftAsInvalid,
  removeAll,
  updateSwitchStepCaseCondition,
  addSwitchStepCase,
  deleteSwitchStepCase,
  moveSwitchStepCase,
} = flowsSlice.actions;

export default flowsSlice.reducer;
