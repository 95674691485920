import { isRejected } from "@reduxjs/toolkit";
import { UnknownAsyncThunkAction } from "@reduxjs/toolkit/src/matchers";
import { createAceHeaders } from "@sapiens-digital/ace-designer-common/lib/helpers/aceHeaders";
import { WorkspaceDetails } from "@sapiens-digital/ace-designer-common/lib/model/git";

import { Step } from "../../model";
import {
  ExecuteFlowApiResult,
  ExecuteFlowOptions,
  ExecuteFlowResult,
} from "../../model/flowExecution";
import { Settings } from "../../model/settings";
import { Workspace } from "../../model/workspace";
import { serializeSteps } from "../../services/flows";
import { getLatestLocalCommitOid } from "../../services/git-utils";
import {
  Environment,
  executeFlow,
  getEnvironment,
  getWorkspaceLocation,
} from "../../services/workspace";
import {
  assertSettings,
  assertWebSettings,
  assertWorkspace,
} from "../utils/assertEntities";

export const processExecuteFlow = async (
  flowRequest: ExecuteFlowOptions,
  settings: Settings,
  currentWorkspace: Workspace | undefined
): Promise<ExecuteFlowResult> => {
  const isWeb = getEnvironment() === Environment.Web;

  let wsDetails;

  if (isWeb) {
    assertWebSettings();
    assertWorkspace(currentWorkspace);
    wsDetails = await getWorkspaceDetails(currentWorkspace, settings);
  }

  if (typeof flowRequest.flow !== "string") {
    flowRequest.flow.steps = serializeSteps(flowRequest.flow.steps as Step[]);
  }

  const result = await executeFlow(flowRequest, wsDetails);

  if ((result as ExecuteFlowApiResult).error) {
    throw new Error((result as ExecuteFlowApiResult).error);
  }

  if ((result as ExecuteFlowApiResult).flowResponse) {
    return (result as ExecuteFlowApiResult).flowResponse as ExecuteFlowResult;
  }

  return result as ExecuteFlowResult;
};

export const getWorkspaceDetails = async (
  currentWorkspace: Workspace,
  settings: Settings
): Promise<WorkspaceDetails> => {
  assertSettings(settings);

  const workspaceLocation = getWorkspaceLocation(currentWorkspace.name);

  const commit = await getLatestLocalCommitOid(
    currentWorkspace.name,
    workspaceLocation
  );

  const wsDetails: WorkspaceDetails = {
    repository: settings.repositoryUrl,
    token: settings.repositoryToken,
    branch: currentWorkspace.name,
    repositoryWorkspacePath: settings.repositoryWorkspacePath,
    commit,
    username: settings.repositoryUsername,
  };

  return wsDetails;
};

/**
 * This method modifies the req object.
 * @param req
 * @param currentWorkspace
 * @param settings
 */
export const addDeploymentDetailsInHeader = async (
  req: Record<string, unknown>,
  currentWorkspace: Workspace,
  settings: Settings
): Promise<void> => {
  if (getEnvironment() === Environment.Web) {
    const details = await getWorkspaceDetails(currentWorkspace, settings);
    const authorization = localStorage.getItem("Authorization") || "";
    req.headers = {
      ...(req.headers as Record<string, unknown>),
      ...createAceHeaders(details, authorization),
    };
    req.credentials = "omit";
  }
};

export const isUnauthorized = (res: UnknownAsyncThunkAction): boolean =>
  isRejected(res) && res.error.message === "unauthorized";
