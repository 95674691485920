/**
 * This is a registry of all entities loaded, from a particular path in the storage,
 * into the application.
 * It provides all entities with unique IDs and stores location of these
 * entities in the registry. Once it is necessary to store the entity into the storage, path where it
 * was loaded from can be retrieved using generated ID.
 */

import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { v4 as uuidv4 } from "uuid";

import { isSubdir, split } from "./fs-utils";

interface SerializedId {
  root: string;
  path: string;
  context?: string;
}

type RegistryStorage = Record<string, SerializedId>;

class Registry {
  private ids: RegistryStorage = {};

  public getId = (key: string) => this.ids[key];

  public setId = (key: string, value: SerializedId) => {
    this.ids[key] = value;
  };

  public deleteId = (key: string) => {
    delete this.ids[key];
  };

  public findEntry = (
    predicate: ([key, value]: readonly [string, SerializedId]) => boolean
  ) => Object.entries(this.ids).find(predicate);

  public filterEntries = (
    predicate: ([key, value]: readonly [string, SerializedId]) => boolean
  ) => Object.entries(this.ids).filter(predicate);

  public reset = () => {
    this.ids = {};
  };
}

const {
  deleteId,
  setId,
  findEntry,
  filterEntries,
  getId,
  reset,
} = new Registry();

const extractSerializedId = (
  root: string,
  pathId: string,
  context: boolean | string = false
): SerializedId => {
  let extractedPath = pathId;
  let extractedContext = undefined;

  if (context) {
    const parts = split(pathId);
    extractedContext = parts.pop();
    extractedPath = posixPath.join(...parts);
  }

  return {
    root,
    path: extractedPath,
    context: extractedContext,
  };
};

const getIdBySerializedId = (serializedId: SerializedId): string | undefined =>
  findEntry(
    ([_, value]) =>
      value.root === serializedId.root &&
      value.path === serializedId.path &&
      value.context === serializedId.context
  )?.[0];

const containerPath = (ref: SerializedId) => {
  if (ref.context === undefined) {
    return posixPath.join(ref.root, posixPath.dirname(ref.path));
  }

  return posixPath.join(ref.root, ref.path);
};

const fullPath = (ref: SerializedId) => {
  if (ref.context === undefined) {
    return posixPath.join(ref.root, ref.path);
  }

  return posixPath.join(ref.root, ref.path, ref.context);
};

const resolvePath = (from: SerializedId, to: string) => {
  const pathFrom = containerPath(from);
  return posixPath.resolve(pathFrom, to);
};

/**
 * Generates a unique ID (for example: '8d1dd6e4-a598-11eb-bcbc-0242ac130002') and
 * registers that this ID points to the artifact in a particular location in the storage.
 *
 * @param root - path (including workspace name) where artifact was loaded from using
 * loadNodes, for example ('develop/flows')
 * @param path - path to the artifact, for example 'flow1.yaml' or 'flow1.yaml/stepName1'
 * @param context - true if path contains child entity in the artifact, for example 'flow1.yaml/stepName1'
 *
 * @returns generated unique ID
 */
export const deserializeId = (
  root: string,
  path: string,
  context = false
): string => {
  const serializedId = extractSerializedId(root, path, context);
  let id = getIdBySerializedId(serializedId);

  if (id !== undefined) {
    return id;
  }

  id = uuidv4();
  setId(id, serializedId);
  return id;
};

/**
 * @param root - path (including workspace name) where artifact was loaded from using
 * loadNodes, for example ('develop/flows')
 * @param path - path to the artifact, for example 'flow1.yaml' or 'flow1.yaml/stepName1'
 * @param context - true if path contains child entity in the artifact, for example 'flow1.yaml/stepName1'
 *
 * @returns retrieves deserialized ID if it exists for artifact
 */
export const getDeserializedId = (
  root: string,
  path: string,
  context = false
): string | undefined => {
  const serializedId = extractSerializedId(root, path, context);
  return getIdBySerializedId(serializedId);
};

/**
 * Generates an unique ID for child entity in the artifact
 *
 * @param childPath child name, for example 'step1'
 * @param parentId parent id which already exists in registry, for example deserialized flow id '8d1dd6e4-a598-11eb-bcbc-0267ac130002'
 */
export const deserializeChildId = (
  childPath: string,
  parentId: string
): string => {
  const ref = getId(parentId);
  return deserializeId(ref.root, posixPath.join(ref.path, childPath), true);
};

/**
 * Converts ID to the POINTER that is serializable (human readable, pointing to the
 * artifact in the storage). For example, converts '8d1dd6e4-a598-11eb-bcbc-0242ac130002' to
 * 'flow1.yaml', given that 'flow1.yaml' , when artifact 'flow1.yaml' was loaded from the disk,
 * got assigned ID '8d1dd6e4-a598-11eb-bcbc-0242ac130002'
 *
 * @param id - unique ID that was assigned by deserializeId() function, for example '8d1dd6e4-a598-11eb-bcbc-0242ac130002'
 * @returns path pointing to the artifact on the disk, for example 'flow1.yaml'
 */
export const serializeId = (id: string): string | undefined => {
  const ref = getId(id);

  if (ref === undefined) {
    return undefined;
  }

  if (ref.context === undefined) {
    return ref.path;
  }

  return posixPath.join(ref.path, ref.context);
};

/**
 * Returns the function that will resolve to the ID once entity is loaded
 * and unique id is generated via deserializeId()
 *
 * @param root - path (including workspace name) where artifact was loaded from using
 * loadNodes, for example ('develop/flows')
 * @param pathId - path to the artifact, for example 'flow1.yaml' or 'flow1.yaml/stepName1'
 * @param context - true if path contains child entity in the artifact, for example 'flow1.yaml/stepName1'
 * @param relativeToId - specify if path was stored not relative to the root but relative to ${relativeToId} entity
 * @returns function that resolves to related entity unique ID
 */
export const deserializeRefId = (
  root: string,
  pathId: string,
  context = false,
  relativeToId?: string
): (() => string | undefined) => {
  if (relativeToId !== undefined) {
    pathId = posixPath.relative(root, resolvePath(getId(relativeToId), pathId));
  }

  const serializedId = extractSerializedId(root, pathId, context);

  return () => getIdBySerializedId(serializedId);
};

/**
 * Serializes IDs used in references to the format that can be used in
 * files stored on the disk.
 * @param id - unique generated ID created by serializeId()
 * @param relativeToId - if specified, calculated path will be relative
 * to the path of ${relativeToId} entity,
 * @returns path that can be stored in a serialized content (for example: 'flow1.yaml')
 */

export const serializeRefId = (
  id: string | undefined,
  relativeToId?: string
): string | undefined => {
  if (id === undefined) {
    return undefined;
  }

  if (relativeToId === undefined) {
    return serializeId(id);
  }

  return posixPath.relative(
    containerPath(getId(relativeToId)),
    fullPath(getId(id))
  );
};

export const resetRefRegistry = (root?: string): void => {
  if (!root) {
    reset();
    return;
  }

  filterEntries(([_, value]) => value.root === root).forEach(([key]) => {
    deleteId(key);
  });
};

/**
 * Updates existing reference with new path or inserts a new registry entry.
 * @example
 * const id = deserializeId("/", "originalPath.yaml")
 * // returns "originalPath.yaml"
 * serializeId(id)
 * // assigns new path to existing reference
 * upsertDeserializedId(id, "/", "newPath.yaml")
 * // return "newPath.yaml"
 * serializeId(id);
 *
 * @param id new id OR existing id that was assigned by deserializeId() function
 * @param newRoot - path (including workspace name) where artifact was loaded from using
 * loadNodes, for example ('develop/flows')
 * @param newPath - NEW path to the artifact, for example 'flow1.yaml' or 'flow1.yaml/stepName1'
 * @param context - true if path contains child entity in the artifact, for example 'flow1.yaml/stepName1'
 */
export const upsertDeserializedId = (
  id: string,
  newRoot: string,
  newPath: string,
  context: string | boolean = false
): void => {
  setId(id, extractSerializedId(newRoot, newPath, context));
};

export const upsertDeserializedChildId = (
  id: string,
  newChildPath: string,
  existingParentId: string
): void => {
  const parent = getId(existingParentId);
  upsertDeserializedId(
    id,
    parent.root,
    posixPath.join(parent.path, newChildPath),
    true
  );
};

/**
 * Updates existing folder reference with new path or inserts a new registry entry.
 * Updates all references under the folder (i.e. its files, subfolders).
 * @example
 * const id = deserializeId("/", "originalPath")
 * const fileId = deserializeId("/", "originalPath/file1.yaml")
 * const folderId = deserializeId("/", "originalPath/subfolder")
 * const nestedFileId = deserializeId("/", "originalPath/subfolder/file2.yaml")
 * // assigns new path to existing folder and all its contents
 * upsertDeserializedFolderId(id, "/", "newPath")
 *
 * // return "newPath"
 * serializeId(id);
 * // return "newPath/file1.yaml"
 * serializeId(fileId);
 * // return "newPath/subfolder"
 * serializeId(folderId);
 * // return "newPath/subfolder/file2.yaml"
 * serializeId(nestedFileId);
 *
 * @param id
 * @param newFolderRoot
 * @param newFolderPath
 * @param context
 */
export const upsertDeserializedFolderId = (
  id: string,
  newFolderRoot: string,
  newFolderPath: string,
  context = false
): void => {
  const oldId = getId(id);
  if (!oldId) return;

  const { path: oldPath, root: oldRoot } = oldId;
  upsertDeserializedId(id, newFolderRoot, newFolderPath, context);

  const idsUnderOldFolder = filterEntries(
    ([_, { path: entryPath, root: entryRoot }]) =>
      entryRoot === oldRoot && isSubdir(oldPath, entryPath)
  );
  idsUnderOldFolder.forEach(([entryId, { path: entryPath, context }]) => {
    const newPath = entryPath.replace(oldPath, newFolderPath);
    upsertDeserializedId(entryId, newFolderRoot, newPath, context);
  });
};
