import React, { useState } from "react";
import { Designer } from "@sapiens-digital/ace-designer-app/app/components/Designer";
import { useAppDispatch } from "@sapiens-digital/ace-designer-app/app/store";
import { initializeDesignerAction } from "@sapiens-digital/ace-designer-app/app/store/designer/actions";

import logo from "./assets/logo.png";
import { LoginPopup } from "./login/LoginPopup";
import { LoginTracker } from "./login/LoginTracker";

function DesignerApp(): React.ReactElement {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useAppDispatch();

  const initDesigner = () => {
    if (isInitialized) {
      return;
    }

    dispatch(initializeDesignerAction());
    setIsInitialized(true);
  };

  return (
    <React.Fragment>
      <LoginTracker />
      <LoginPopup onLogin={initDesigner} />
      <Designer logo={logo} />
    </React.Fragment>
  );
}

export default DesignerApp;
