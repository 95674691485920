import React, { useCallback } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, IconButton, Tooltip } from "@mui/material";
import { SettingsManager } from "services/settingsManager";

import { useAppSelector } from "../../store";
import { selectUsername } from "../../store/designer/selectors";
import { renderInWeb } from "../RenderOnlyInEnv";

const KEYCLOAK_LOGOUT_PATH = "/auth/keycloak/logout";

const UserLogoutBase = () => {
  const username = useAppSelector(selectUsername);

  const logout = useCallback(() => {
    window.open(
      SettingsManager.getDesignerConfigs().devApiBaseUrl + KEYCLOAK_LOGOUT_PATH
    );
  }, []);

  return username ? (
    <>
      <Tooltip title={username} placement="top" enterDelay={500}>
        <Box
          sx={{
            color: "white",
            px: 1,
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          {username}
        </Box>
      </Tooltip>
      <Tooltip title="Logout" enterDelay={500}>
        <IconButton
          data-testid="logout"
          sx={{ color: "white" }}
          onClick={logout}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </>
  ) : null;
};

export const UserLogout = renderInWeb(UserLogoutBase);
