import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericAsyncFunction = (...args: any) => Promise<any>;
type IsLoading = boolean;
type UseIsLoadingReturns<TAsyncFunction extends GenericAsyncFunction> = [
  isLoading: IsLoading,
  asyncFunction: TAsyncFunction
];

export function useIsLoading<TAsyncFunction extends GenericAsyncFunction>(
  asyncFunction: TAsyncFunction
): UseIsLoadingReturns<TAsyncFunction> {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const callAsyncFunction = useCallback<GenericAsyncFunction>(
    async (...args) => {
      setIsLoading(true);
      const result = await asyncFunction(...args);
      setIsLoading(false);

      return result;
    },
    [asyncFunction]
  );

  return [isLoading, callAsyncFunction as TAsyncFunction];
}
