import React, { ComponentType } from "react";

import { Environment, getEnvironment } from "../services/workspace";

const renderInEnv = (
  env: Environment
  // eslint-disable-next-line @typescript-eslint/ban-types
) => <P extends object>(Component: ComponentType<P>) => (props: P) => {
  if (getEnvironment() === env) {
    return <Component {...props} />;
  }

  return null;
};

export const renderInWeb = renderInEnv(Environment.Web);
export const renderInElectron = renderInEnv(Environment.Electron);
