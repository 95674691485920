import { ExecuteFlowOptions } from "@sapiens-digital/ace-designer-app/app/model/flowExecution";
import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";
import { createAceHeaders } from "@sapiens-digital/ace-designer-common/lib/helpers/aceHeaders";
import { WorkspaceDetails } from "@sapiens-digital/ace-designer-common/lib/model/git";

import { getTokenFromLocalStorage } from "../login/utils";

const postWithDeploymentDetails = async (
  path: string,
  wsDetails: WorkspaceDetails,
  body: Record<string, unknown>
) => {
  const authorization = getTokenFromLocalStorage() || "";
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      ...createAceHeaders(wsDetails, authorization),
    } as HeadersInit,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(
      SettingsManager.getDesignerConfigs().devApiBaseUrl + path,
      options
    );

    if (response?.status === 401) {
      window.dispatchEvent(new CustomEvent("authfail"));
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return {
      status: "ERROR",
      error: "Some Error in connecting to Development server",
    };
  }
};

export const executeFlow = async (
  executeFlowOpts: ExecuteFlowOptions,
  deployReq: WorkspaceDetails
): Promise<unknown> => {
  const flowRequest: Record<string, unknown> = { ...executeFlowOpts };
  if (typeof executeFlowOpts.flow === "string") {
    flowRequest.flowName = executeFlowOpts.flow;
    delete flowRequest.flow;
  }

  return postWithDeploymentDetails("/execute", deployReq, { flowRequest });
};
