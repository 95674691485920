import { Provider } from "react-redux";
import {
  container,
  symbols,
} from "@sapiens-digital/ace-designer-app/app/services";
import { resetRefRegistry } from "@sapiens-digital/ace-designer-app/app/services/references";
import { store } from "@sapiens-digital/ace-designer-app/app/store";
import { createAppender } from "@sapiens-digital/ace-designer-app/app/utils/logMemoryAppender";

import { executeFlow } from "./services/devApis";
import {
  getEnvVariables,
  loadDesignerSettings,
  loadWorkspaceSettings,
  saveDesignerSettings,
  saveWorkspaceSettings,
} from "./services/settings";
import {
  getEnvironment,
  getWorkspaceFS,
  getWorkspaceHttpClient,
} from "./services/workspace";
import DesignerApp from "./DesignerApp";

import "./index.css";

container.bind(symbols.LoadDesignerSettings).toFunction(loadDesignerSettings);
container.bind(symbols.SaveDesignerSettings).toFunction(saveDesignerSettings);
container.bind(symbols.LoadWorkspaceSettings).toFunction(loadWorkspaceSettings);
container.bind(symbols.SaveWorkspaceSettings).toFunction(saveWorkspaceSettings);
container.bind(symbols.GetWorkspaceFS).toFunction(getWorkspaceFS);
container.bind(symbols.GetEnvironment).toFunction(getEnvironment);
container.bind(symbols.EmitAppEvent).toFunction(() => void {});
container.bind(symbols.ExecuteFlow).toFunction(executeFlow);
container
  .bind(symbols.GetWorkspaceHttpClient)
  .toFunction(getWorkspaceHttpClient);
container.bind(symbols.GetEnvironmentVariables).toFunction(getEnvVariables);
createAppender();

resetRefRegistry();

export const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <DesignerApp />
    </Provider>
  );
};
