import { ObjKeys } from "./ObjectUtils";

type ConsoleLoggingMethods = keyof Pick<
  Console,
  "log" | "info" | "warn" | "error" | "debug"
>;

const ALL_SUPPORTED_METHODS: ConsoleLoggingMethods[] = [
  "log",
  "warn",
  "error",
  "info",
  "debug",
];

const ORIGINAL_METHODS = ALL_SUPPORTED_METHODS.reduce((acc, method) => {
  acc[method] = console[method];
  return acc;
}, {} as Record<ConsoleLoggingMethods, typeof console.log>);

const logs: string[] = [];

export function createAppender(
  supportedMethods: ConsoleLoggingMethods[] = ALL_SUPPORTED_METHODS
): void {
  supportedMethods.forEach((method) => {
    console[method] = (...args: unknown[]) => {
      logs.push(formatMessage(method, args));
      ORIGINAL_METHODS[method](...args);
    };
  });
}

export function removeAppender(): void {
  ObjKeys(ORIGINAL_METHODS).forEach((method) => {
    console[method] = ORIGINAL_METHODS[method];
  });
}

export function getLogs(): string[] {
  return logs;
}

function formatMessage(method: ConsoleLoggingMethods, args: unknown[]) {
  const formatErrorMessage = args.join(" ").replaceAll("\n", "; ");
  return [
    method.toUpperCase(),
    new Date().toISOString(),
    `${formatErrorMessage}\n`,
  ].join(", ");
}
