import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { ConfirmationDialog } from "components/dialogs";
import { Environment, getEnvironment } from "services/workspace";
import { addNotification } from "store/designer/actions";

import { deleteIndexedDb } from "./deleteIndexedDBUtil";
import { downloadMenuButtonStyles } from "./LogExporter";

export function deleteAllCookies(): void {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}

const addErrorNotification = () => {
  addNotification({
    message: "Error in clearing designer data",
    variant: "error",
  });
};

const clearDesignerData = async () => {
  localStorage.clear();
  sessionStorage.clear();
  const isElectron = getEnvironment() === Environment.Electron;

  if (!isElectron) {
    await deleteIndexedDb();
  }

  deleteAllCookies();
  window.location.reload();
};

export const ClearDesignerData: React.FC<{
  setIsClearingData: React.Dispatch<React.SetStateAction<boolean>>;
  isClearingDesignerData: boolean;
}> = ({ setIsClearingData, isClearingDesignerData }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <ConfirmationDialog
        open={isDialogOpen}
        title="CLEAR DESIGNER DATA"
        loading={isClearingDesignerData}
        handleClose={() => {
          setDialogOpen(false);
        }}
        handleOk={async (e: React.SyntheticEvent) => {
          e.stopPropagation();
          setIsClearingData(true);

          try {
            await clearDesignerData();
          } catch (err) {
            console.error(err);
            addErrorNotification();
          }

          setIsClearingData(false);
          setDialogOpen(false);
        }}
      >
        <Typography>
          Do you want to clear all Designer data in this browser? It does not
          affect stored git data. You will need to set up Designer from scratch.
        </Typography>
      </ConfirmationDialog>

      <Button
        component="div"
        data-testid="clearDesignerData"
        sx={{ ...downloadMenuButtonStyles, marginLeft: "9px" }}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
          setDialogOpen(true);
        }}
      >
        CLEAR DESIGNER DATA
      </Button>
    </>
  );
};
