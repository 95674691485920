import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ButtonComponent from "@sapiens-digital/ace-designer-app/app/components/ButtonComponent";
import { useAppDispatch } from "@sapiens-digital/ace-designer-app/app/store";
import { setUsername } from "@sapiens-digital/ace-designer-app/app/store/designer/reducers";

import { getUsernameFromToken } from "./JWTTokenUtils";
import { useToken } from "./useToken";
import { getKeycloakAuthUrl, isKeycloakAuthenticationEnabled } from "./utils";

interface LoginPopupProps {
  onLogin: () => void;
}

export const LoginPopup: React.FC<LoginPopupProps> = ({ onLogin }) => {
  const { token, clearToken } = useToken();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isKeycloakAuthenticationEnabled() && !token) {
      setOpen(true);
    } else if (!isKeycloakAuthenticationEnabled() || token) {
      setOpen(false);
      onLogin();
    }
  }, [onLogin, token]);

  useEffect(() => {
    dispatch(setUsername(getUsernameFromToken(token)));
  }, [dispatch, token]);

  useEffect(() => {
    const onFailedAuthForRequest = (): void => {
      setOpen(true);
      clearToken();
    };

    window.addEventListener("authfail", onFailedAuthForRequest);
    return () => {
      window.removeEventListener("authfail", onFailedAuthForRequest);
    };
  }, [clearToken]);

  const login = useCallback((): void => {
    window.open(getKeycloakAuthUrl());
  }, []);

  const onClose = () => !isKeycloakAuthenticationEnabled() && setOpen(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Designer server login</DialogTitle>
      <DialogContent>You must be logged in to continue</DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!isKeycloakAuthenticationEnabled() && (
          <ButtonComponent
            variant="text"
            onClick={onClose}
            data-testid="login-popup-close-btn"
          >
            Cancel
          </ButtonComponent>
        )}
        <ButtonComponent variant="contained" onClick={login}>
          Open login page in new window
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
