import {
  SerializedApi,
  WORKSPACE_PATHS,
} from "@sapiens-digital/ace-designer-common";
import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import merge from "lodash/merge";
import { exists, isDir, readYaml, writeYaml } from "services/fs-utils";

import { getWorkspaceFS } from "../workspace";

export const mergeApiFile = async (
  sourcePath: string,
  destPath: string
): Promise<void> => {
  const sourceApi: SerializedApi = (await readYaml(
    sourcePath
  )) as SerializedApi;

  if (await exists(destPath)) {
    const destApi: SerializedApi = (await readYaml(destPath)) as SerializedApi;
    await writeYaml(destPath, merge(destApi, sourceApi));
  } else {
    await writeYaml(destPath, sourceApi);
  }
};

export const importApiFiles = async (
  sourceRepositoryLocation: string,
  repositoryLocation: string
): Promise<void> => {
  const fs = getWorkspaceFS();
  const apisFolderPath = posixPath.join(
    sourceRepositoryLocation,
    WORKSPACE_PATHS.APIS
  );

  if (!(await exists(apisFolderPath)) || !(await isDir(apisFolderPath))) {
    return;
  }

  const files: string[] = await fs.promises.readdir(apisFolderPath);
  await Promise.all(
    files
      .filter((file) => file.toLocaleLowerCase().endsWith(".yaml"))
      .map(async (file) => {
        const fileInRepo = posixPath.join(
          repositoryLocation,
          WORKSPACE_PATHS.APIS,
          file
        );
        const fileInImported = posixPath.join(apisFolderPath, file);
        await mergeApiFile(fileInImported, fileInRepo);
      })
  );
};
