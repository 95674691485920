import { PickKeysOfType } from "../utils/types/PickKeysOfType";

import { Folder } from "./file";
import { ValidatedSettings } from "./settings";

export enum WorkspaceVersion {
  INVALID = "INVALID",
  UNKNOWN = "UNKNOWN",
  V2 = "V2",
}

export type OpenTabbableIds = Array<string>;

// Workspace does not reference any model entities directly
// but rather collects and maintains pointers to data sources
// that contain entity data
export interface Workspace {
  id: string;
  name: string; // remote branch name
  location: string; // workspace location
  repositoryWorkspacePath: string; // where in workspace are ACE files (for example src/ace or folder1/src/ace)
  selectedFlowId?: string; // currently selected (opened) flow
  flowIds: OpenTabbableIds; // all opened flows
  apiIds: OpenTabbableIds; // all opened apis
  flows: Folder; // directories and flows in "flows" folder
  apis: Folder; // root api definitions in "apis" folder
  schemas: Folder; // reusable model entity definitions (schemas) in "apis/schemas" directory
  modelFields: Folder; // reusable model field definitions in "apis/fields" directory
  modelHeaders: Folder; // reusable model api header definitions in "apis/headers" directory
  modelParameters: Folder; // reusable model api parameter definitions in "apis/parameters" directory
  modelResponses: Folder; // reusable model api response definitions in "apis/responses" directory
  schedules: Folder; // schedules in "schedules" folder
  variables: Folder; // variables in "variables" folder
  errorHandlers: Folder; // error handlers in "error-handlers" folder
  virtualSteps: Folder; // virtual steps "virtual-steps" folder
  version: WorkspaceVersion; // V1 for old designer, V2 for a new one. Prompt designer to proceed with import if opening V1 workspace is loaded
}

export type SelectedTabbableKeys = keyof Workspace & `selected${string}`;
export type OpenTabbableIdsKeys = keyof Workspace & `${string}Ids`;

export type SerializedWorkspace = Pick<
  Workspace,
  | "name"
  | "version"
  | "repositoryWorkspacePath"
  | SelectedTabbableKeys
  | OpenTabbableIdsKeys
>;

export type WorkspaceFolder = PickKeysOfType<Workspace, Folder>;

export interface Remote {
  id: string;
  name: string;
}

export type ExtendedSettings = Pick<
  ValidatedSettings,
  | "workspacesLocation"
  | "repositoryWorkspacePath"
  | "repositoryUrl"
  | "repositoryToken"
  | "repositoryUsername"
> & { workspaceName: Workspace["name"] };

export interface GitDetails {
  branchOrTagName: string;
  gitUrl: string;
  gitUsername: string;
  gitPassword: string;
  sourcePath?: string;
}

export interface LocalWorkspace {
  id: string;
  folderName: string;
}

export interface WorkspaceSettings {
  workspaces: LocalWorkspace[];
}
