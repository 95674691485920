import { Container } from "inversify";

export const container = new Container();

export const symbols = {
  LoadDesignerSettings: Symbol.for("LoadDesignerSettings"),
  SaveDesignerSettings: Symbol.for("SaveDesignerSettings"),
  LoadWorkspaceSettings: Symbol.for("LoadWorkspaceSettings"),
  SaveWorkspaceSettings: Symbol.for("SaveWorkspaceSettings"),
  GetWorkspaceFS: Symbol.for("GetWorkspaceFS"),
  GetWorkspaceHttpClient: Symbol.for("GetWorkspaceHttpClient"),
  GetEnvironment: Symbol.for("GetEnvironment"),
  ExecuteFlow: Symbol.for("ExecuteFlow"),
  EmitAppEvent: Symbol.for("EmitAppEvent"),
  GetEnvironmentVariables: Symbol.for("GetEnvironmentVariables"),
};
