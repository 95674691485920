import { createSlice, isAnyOf, isFulfilled } from "@reduxjs/toolkit";

import { Settings } from "../../model/settings";

import {
  loadDesignerSettingsAction,
  removeGitAuthorFromKeycloakAction,
  saveDesignerSettingsAction,
  saveGitAuthorFromKeycloakAction,
  selectWorkspaceAction,
} from "./actions";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {} as Settings,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectWorkspaceAction, (state, action) => {
        state.selectedWorkspaceId = action.payload;
      })
      .addMatcher(
        isAnyOf(
          isFulfilled(
            saveGitAuthorFromKeycloakAction,
            removeGitAuthorFromKeycloakAction,
            loadDesignerSettingsAction,
            saveDesignerSettingsAction
          )
        ),
        (state, action) => action.payload
      );
  },
});

export default settingsSlice.reducer;
