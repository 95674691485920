import path from "path";
import { readError } from "store/utils/readError";

import { mkdir } from "../../fs-utils";

import migrateApi from "./migrateApi";
import migrateErrorHandler from "./migrateErrorHandler";
import migrateFlow from "./migrateFlow";
import migrateScheduler from "./migrateScheduler";
import migrateSchema from "./migrateSchema";
import {
  getV1WorkspaceEntityPaths,
  readJsonFileAsPlainObject,
} from "./migrateUtils";
import migrateWorkspaceVariable from "./migrateWorkspaceVariable";

export type PlainObject = Record<string, unknown>;
export interface MigratorOptions {
  targetRepositoryLocation: string;
  fileBasename: string;
  repositoryLocation?: string;
  overwriteExisting?: boolean;
  content: PlainObject | PlainObject[];
  workspaceEntityPaths: V1Entities;
}
export interface MigrationError {
  filePath: string;
  message: string;
}

export type V1EntityMigrator = (
  options: MigratorOptions
) => Promise<MigrationError[]>;

interface MigrationOptions {
  overwriteExisting?: boolean | undefined;
  repositoryLocation?: string | undefined;
  folder: string;
  migrators: V1FolderMigratorMap;
  targetRepositoryLocation: string;
  filePaths: string[];
  workspaceEntityPaths: V1Entities;
}

type Migrator = (optoins: MigrationOptions) => Promise<MigrationError[]>;

export type V1FolderMigratorMap = Record<V1Folder, V1EntityMigrator>;
export type V1Entities = Record<V1Folder, string[]>;
export enum V1Folder {
  Schemas = "apiSchemas",
  Schedules = "cronJobs",
  Apis = "dynamicApis",
  EnvVars = "envVariables",
  ErrorHandlers = "errorHandler",
  Flows = "flows",
  Swagger = "swagger",
}

const migrateSkip: V1EntityMigrator = async () => [];

const migratorMap: V1FolderMigratorMap = {
  [V1Folder.Schemas]: migrateSchema,
  [V1Folder.Schedules]: migrateScheduler,
  [V1Folder.Apis]: migrateApi,
  [V1Folder.EnvVars]: migrateWorkspaceVariable,
  [V1Folder.ErrorHandlers]: migrateErrorHandler,
  [V1Folder.Flows]: migrateFlow,
  [V1Folder.Swagger]: migrateSkip,
};

export const migrateV1EntitiesToV2 = async (
  sourceWorkspaceLocation: string,
  targetRepositoryLocation: string,
  repositoryLocation?: string,
  overwriteExisting?: boolean,
  migrators = migratorMap
): Promise<MigrationError[]> => {
  let migrationErrors: MigrationError[] = [];
  const workspaceEntityPaths = await getV1WorkspaceEntityPaths(
    sourceWorkspaceLocation
  );
  const folderedEntities = Object.entries(workspaceEntityPaths);

  await mkdir(targetRepositoryLocation);

  for (const [folder, filePaths] of folderedEntities) {
    const migrationOptions: MigrationOptions = {
      overwriteExisting,
      repositoryLocation,
      folder,
      migrators,
      targetRepositoryLocation,
      filePaths,
      workspaceEntityPaths,
    };
    const combineFiles = ["dynamicApis", "envVariables"].includes(folder);
    const result = combineFiles
      ? await migrateMultipleFiles(migrationOptions)
      : await migrateFile(migrationOptions);
    migrationErrors = [...migrationErrors, ...result];
  }

  return migrationErrors;
};

const migrateFile: Migrator = async ({
  filePaths,
  folder,
  migrators,
  targetRepositoryLocation,
  workspaceEntityPaths,
}) => {
  const migrationErrors: MigrationError[] = [];

  for (const file of filePaths) {
    try {
      const content = await readJsonFileAsPlainObject(file);
      const errors = await migrators[folder as V1Folder]({
        fileBasename: path.basename(file, ".json"),
        content,
        targetRepositoryLocation,
        workspaceEntityPaths,
      });
      migrationErrors.push(...errors);
    } catch (e) {
      migrationErrors.push({ filePath: file, message: readError(e) });
    }
  }

  return migrationErrors;
};

const migrateMultipleFiles: Migrator = async ({
  filePaths,
  folder,
  migrators,
  targetRepositoryLocation,
  overwriteExisting,
  repositoryLocation,
  workspaceEntityPaths,
}) => {
  const content: PlainObject[] = [];
  const migrationErrors: MigrationError[] = [];

  for (const file of filePaths) {
    try {
      content.push(await readJsonFileAsPlainObject(file));
    } catch (e) {
      migrationErrors.push({ filePath: file, message: readError(e) });
    }
  }

  try {
    const errors = await migrators[folder as V1Folder]({
      fileBasename: "",
      content,
      repositoryLocation,
      targetRepositoryLocation,
      overwriteExisting,
      workspaceEntityPaths,
    });
    migrationErrors.push(...errors);
  } catch (e) {
    migrationErrors.push({
      filePath: targetRepositoryLocation + folder,
      message: readError(e),
    });
  }

  return migrationErrors;
};
