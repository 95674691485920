import React from "react";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";

import { getLogs } from "../../utils/logMemoryAppender";

export const downloadMenuButtonStyles = {
  fontSize: "11px",
  marginLeft: "4px",
  padding: "0 !important",
  minWidth: "42px !important",
  lineHeight: "11px",
  border: "none",
  hover: {
    border: "none",
  },
};

export const LogExporter: React.FC = () => (
  <Button component="div" sx={downloadMenuButtonStyles} onClick={exportLogs}>
    LOGS
  </Button>
);

function exportLogs() {
  const blob = new Blob(getLogs(), {
    type: "text/plain;charset=utf-8",
  });
  saveAs(blob, `designer-logs ${new Date()}.txt`);
}
