import React, { useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";
import { AccessDeniedPopup } from "views/auth/AccessDeniedPopup";

import {
  API_DEFINITION_PATH,
  APIDefinition,
} from "./views/api-definition/APIDefinition";
import { APIsList } from "./views/apis-list/APIsList";
import { App } from "./App";

const Root: React.FC = () => {
  const [isInit, setIsInit] = useState(false);
  const [isErrorInFetchConfig, setIsErrorInFetchConfig] = useState(false);

  const init = async () => {
    try {
      await SettingsManager.init();
      setIsInit(true);
    } catch (e) {
      setIsErrorInFetchConfig(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (!isInit || isErrorInFetchConfig) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isErrorInFetchConfig ? (
          <div>ACE Designer is not available, please try again later.</div>
        ) : (
          <CircularProgress data-testid="initial-loader" />
        )}
      </div>
    );
  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/api-list" element={<APIsList />} />
        <Route path="/access-denied" element={<AccessDeniedPopup />} />
        <Route path={API_DEFINITION_PATH} element={<APIDefinition />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </HashRouter>
  );
};

export default Root;
