import { createSelector } from "@reduxjs/toolkit";
import { getApiOperationList } from "@sapiens-digital/ace-designer-common";
import { AceApiOperation, Api } from "model";
import { OpenAPIV3 } from "openapi-types";

import {
  API_OPERATION_PARTS_DELIMITER,
  extractApiId,
} from "../../utils/extractApiId";
import { ROOT_NODE_ID } from "../designer/constants";
import { selectApiSelectedTreeItemId } from "../designer/pageSelectors";
import { GenericState, Redoable } from "../utils/redoableSliceFactory";
import { RootState } from "..";

export const nameSelector = (api: Api): string => api.name;

const selectSelectedStateApi = createSelector(
  (state: RootState) => state.apis,
  selectApiSelectedTreeItemId,
  (apis, selectedApiTreeEntityId) =>
    selectStateApi(apis, extractApiId(selectedApiTreeEntityId))
);

const selectStateApiByFullId = createSelector(
  (state) => state.apis,
  (_state: RootState, apiId: string) => apiId,
  (apis, apiId) => selectStateApi(apis, extractApiId(apiId))
);

const selectStateApi = (
  state: GenericState<Api>,
  baseApiId: string
): Redoable<Api> | undefined =>
  state.find((api) => api.present.id === baseApiId);

export const selectSelectedAPI = createSelector(
  selectSelectedStateApi,
  (api) => api?.present
);

const selectAllApis = createSelector(
  (state: RootState) => state.apis,
  selectApiSelectedTreeItemId,
  (apis, selectedApiTreeEntityId) => {
    if (!selectedApiTreeEntityId) {
      return apis.map((api) => api.present);
    }

    const [id] = selectedApiTreeEntityId.split(API_OPERATION_PARTS_DELIMITER);

    if (id === ROOT_NODE_ID) {
      return apis.map((api) => api.present);
    }

    if (id) {
      const selectedApi: Redoable<Api> | undefined = apis.find(
        (api) => api.present.id === id
      );
      return selectedApi ? [selectedApi.present] : [];
    }

    return apis.map((api) => api.present);
  }
);

export const getOperationId = (path: string, method: string): string =>
  `${path}#${method}`;

type OperationId = { path: string; verb: string };

export const splitOperationId = (id: string): OperationId => {
  const [path, verb] = id.split("#");
  return { path, verb };
};

export type PathOperation = {
  path: string;
  method: string;
  content: OpenAPIV3.OperationObject;
  id: string;
};

const getOperationsInPath = (
  endpoint: OpenAPIV3.PathItemObject,
  path: string
): PathOperation[] =>
  Object.values(OpenAPIV3.HttpMethods).reduce<PathOperation[]>(
    (operations, method) => {
      const operation = endpoint[method];
      if (!operation) return operations;
      return [
        ...operations,
        {
          path,
          method,
          id: getOperationId(path, method),
          content: operation as OpenAPIV3.OperationObject,
        },
      ];
    },
    []
  );

export const getOperationsFromPaths = (
  paths: OpenAPIV3.PathsObject
): PathOperation[] =>
  Object.entries(paths).reduce<PathOperation[]>(
    (operations, [path, endpoint]) =>
      endpoint
        ? [...operations, ...getOperationsInPath(endpoint, path)]
        : operations,
    []
  );

export const selectSelectedApiOperationsByTag = createSelector(
  selectAllApis,
  selectApiSelectedTreeItemId,
  (allPis, selectedApiTreeEntityId) => {
    if (!allPis) return [];

    const mergedOperations: AceApiOperation[] = allPis.reduce<
      AceApiOperation[]
    >((acc, api) => [...acc, ...getApiOperationList(api.paths, api.id)], []);
    const [, tag] =
      selectedApiTreeEntityId?.split(API_OPERATION_PARTS_DELIMITER) || [];

    if (tag) {
      return mergedOperations.filter((api) => api.tags?.includes(tag));
    }

    return mergedOperations;
  }
);

export const selectSelectedApiOperations = createSelector(
  selectSelectedAPI,
  (api) => (api ? getApiOperationList(api.paths, api.id) : [])
);

/**
 * Full id is `apiId#operationId#verb`
 */
export const selectApiOperationsByFullApiId = createSelector(
  selectStateApiByFullId,
  (api) =>
    api?.present ? getApiOperationList(api.present.paths, api.present.id) : []
);

const getTagsFromApi = (apiOperations: AceApiOperation[], api?: Api) => {
  const operationTags = apiOperations.map((o) => o.tags || []).flat();
  const rootTags = api?.tags?.map((t) => t.name) || [];
  const uniqueTags = Array.from(new Set([...operationTags, ...rootTags]));
  return uniqueTags.map((o) => ({ label: o, value: o }));
};

export const selectApiTags = createSelector(
  selectSelectedApiOperations,
  selectSelectedAPI,
  getTagsFromApi
);

export const selectApiTagsByApiId = createSelector(
  selectApiOperationsByFullApiId,
  selectStateApiByFullId,
  (apiOperations, redoableApi) =>
    getTagsFromApi(apiOperations, redoableApi?.present)
);

const selectApiById = createSelector(
  (state: RootState) => state.apis,
  (_: RootState, id: string) => id,
  (apis, id) => apis.find((api) => api.present.id === id)
);
export const selectApi = createSelector(selectApiById, (api) => api?.present);

export const isEqualOperationPath = (
  oldOp: PathOperation,
  newOp: PathOperation
): boolean => oldOp.method === newOp.method && oldOp.path === newOp.path;

export const selectSelectedApiName = createSelector(
  selectSelectedAPI,
  (selectedApi) => {
    if (!selectedApi) {
      return "";
    }

    return selectedApi.name;
  }
);
