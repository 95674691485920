import { utils } from "@rjsf/core";
import {
  mapPrimitiveValuesOfObject,
  Steps,
} from "@sapiens-digital/ace-designer-common";
import updateStepConfigWithCustomDefault from "@sapiens-digital/ace-designer-common/lib/helpers/stepHelper";
import { flowRefSteps } from "@sapiens-digital/ace-designer-common/lib/steps/stepRegistry";
import { JSONSchema7 } from "json-schema";
import cloneDeep from "lodash/cloneDeep";
import isString from "lodash/isString";
import { v4 as uuid4 } from "uuid";

import {
  Flow,
  SerializedFlow,
  SerializedStep,
  Step,
  VirtualStep,
} from "../model";
import { Schema } from "../model/schemas";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/flows/selectors";

import { addMetadata, removeUsageOfEntity } from "./indexing/indexer";
import { updateUsage, updateUsageById } from "./indexing/updateUsage";
import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  SaveEntity,
} from "./entityService.types";
import {
  deleteFile,
  getFileDisplayName,
  moveFile,
  readFile,
  saveFile,
} from "./fs-utils";
import {
  deserializeChildId,
  getDeserializedId,
  serializeId,
} from "./references";
import { createVirtualStepInstanceSchema } from "./virtualSteps";
import { getContentRoot } from "./workspace";

export const REFERENCES_INITIALIZED_EVENT_NAME = "references-initialized";
export const FLOW_METADATA_UPDATED_EVENT_NAME = "flow-metadata-updated";

declare global {
  type FlowMetadataUpdatedEvent = CustomEvent<{ id: string }>;

  interface WindowEventMap {
    [REFERENCES_INITIALIZED_EVENT_NAME]: Event;
    [FLOW_METADATA_UPDATED_EVENT_NAME]: FlowMetadataUpdatedEvent;
  }
}

const REGEX_UUID_V4 =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

/**
 * Obtains the flow from workspace
 * @param id flow id
 * @param workspace workspace
 * @returns flow stored in file system
 */
export const getFlow: GetEntity<Flow> = async (id, workspace) => {
  try {
    const fileFlow = (await readFile(id, workspace, "flows")) as SerializedFlow;
    removeUsageOfEntity(id, "flows");
    return deserializeFlow(id, fileFlow, workspace);
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves flow to workspace
 * @param flow dynamic flow to save
 * @param workspace workspace
 * @param targetPath
 * @returns saved flow
 */
export const saveFlow: SaveEntity<Flow> = async (
  flow,
  workspace,
  targetPath
) => {
  const name = nameSelector(flow);

  try {
    const cleanFlow = serializeFlow(flow, workspace);

    await saveFile(flow.id, workspace, "flows", cleanFlow, name, targetPath);

    return flow;
  } catch (e) {
    console.error(e);
    throw new Error(`Flow "${name}" can not be saved`);
  }
};

export const moveFlow: MoveEntity<Flow> = async (id, workspace, targetPath) => {
  try {
    await moveFile(id, workspace, "flows", targetPath);
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be moved`);
  }
};

/**
 * Deletes flow from workspace by id
 * @param id flow id
 * @param workspace workspace
 * @returns id of deleted flow it operation was successful
 */
export const deleteFlow: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "flows");
    removeUsageOfEntity(id, "flows");
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be deleted`);
  }
};

function getStepName(fileStep: SerializedStep, steps: Step[]) {
  const nameCandidate = fileStep.name ?? fileStep.stepType;
  let finalName = nameCandidate;
  let nameIndex = 1;
  const stepNameExists = (stepName: string): boolean =>
    steps.find((s) => s.name === stepName) !== undefined;

  while (stepNameExists(finalName)) {
    nameIndex++;
    finalName = `${nameCandidate} ${nameIndex}`;
  }

  return finalName;
}

function deserializeRefAndUpdateUsage(
  val: unknown,
  workspace: Workspace,
  stepType: string,
  flowId?: string
): string | undefined {
  if (!isString(val) || !val.endsWith(".yaml")) {
    return undefined;
  }

  if (flowRefSteps.includes(stepType)) {
    const flowRoot = getContentRoot(workspace, "flows");
    const flowRef = getDeserializedId(flowRoot, val);

    if (flowRef) {
      if (flowId) {
        updateUsageById(
          { id: flowId, entityType: "flows" },
          { flows: [flowRef] }
        );
      }

      return flowRef;
    }
  }

  const schemaRoot = getContentRoot(workspace, "schemas");
  const schemaRef = getDeserializedId(schemaRoot, val);

  if (schemaRef && flowId) {
    updateUsageById(
      { id: flowId, entityType: "flows" },
      { schemas: [schemaRef] }
    );
  }

  return undefined;
}

function deserializeStepConfig(
  config: SerializedStep["config"],
  workspace: Workspace,
  stepType: string,
  flowId?: string
): Step["config"] {
  return mapPrimitiveValuesOfObject(config ?? {}, (val: unknown) => {
    const ref = deserializeRefAndUpdateUsage(val, workspace, stepType, flowId);
    return ref || val;
  });
}

function serializeRefAndUpdateUsage(
  val: unknown,
  workspace?: Workspace,
  flowId?: string
): string | undefined {
  if (!isString(val)) {
    return undefined;
  }

  if (val.match(REGEX_UUID_V4)) {
    const flowRef = serializeId(val);

    if (flowRef && flowId) {
      updateUsageById({ id: flowId, entityType: "flows" }, { flows: [val] });
    }

    return flowRef;
  }

  if (val.endsWith(".yaml") && flowId && workspace) {
    const schemaRoot = getContentRoot(workspace, "schemas");
    const schemaRef = getDeserializedId(schemaRoot, val);
    updateUsageById(
      { id: flowId, entityType: "flows" },
      { schemas: [schemaRef] }
    );
  }

  return undefined;
}

function serializeStepConfig(
  config: Step["config"],
  flowId?: string,
  workspace?: Workspace
): SerializedStep["config"] {
  return mapPrimitiveValuesOfObject(config ?? {}, (val: unknown) => {
    const ref = serializeRefAndUpdateUsage(val, workspace, flowId);
    return ref || val;
  });
}

export function deserializeSteps(
  fileSteps: SerializedStep[],
  flowId: string,
  workspace: Workspace,
  parentStep?: SerializedStep
): Step[] {
  if (!fileSteps) {
    return [];
  }

  const steps: Step[] = [];

  for (const fileStep of fileSteps) {
    if (fileStep.cases) {
      fileStep.cases = deserializeSteps(
        fileStep.cases as SerializedStep[],
        flowId,
        workspace,
        fileStep
      );
    }

    if (fileStep.defaultCase) {
      fileStep.defaultCase = deserializeSteps(
        fileStep.defaultCase as SerializedStep[],
        flowId,
        workspace,
        fileStep
      );
    }

    // switch step nested "cases" steps
    if (fileStep.steps) {
      fileStep.steps = deserializeSteps(
        fileStep.steps as SerializedStep[],
        flowId,
        workspace,
        fileStep
      );

      // @ts-expect-error: swith step cases array can have nested steps, so just pass them along without step id.
      steps.push({
        ...fileStep,
      });
      continue;
    }

    const nestedNamePrefix = parentStep ? uuid4() : "";
    const name = getStepName(fileStep, steps);
    const id = serializeId(flowId)
      ? deserializeChildId(`${nestedNamePrefix}${name}`, flowId)
      : `${flowId}-${nestedNamePrefix}${name}`;

    const step = {
      ...fileStep,
      id: id.replace(/\s+/g, ""),
      name: name,
      stepType: fileStep.stepType,
      description: fileStep.description,
      condition: fileStep.condition,
      config: deserializeStepConfig(
        fileStep.config,
        workspace,
        fileStep.stepType,
        flowId
      ),
    };

    if (fileStep.virtualStepId) {
      updateUsage(
        { id: flowId, entityType: "flows" },
        { virtualSteps: [fileStep.virtualStepId] },
        workspace
      );
    }

    updateStepConfigWithCustomDefault(step);
    steps.push(step);
  }

  return steps;
}

export const serializeSteps = (
  steps: Step[],
  flowId?: string,
  workspace?: Workspace
): SerializedStep[] => {
  if (flowId) {
    removeUsageOfEntity(flowId, "flows");
  }

  // @ts-expect-error: nested cases typing
  return steps.map((step) => {
    const localStep = cloneDeep(step);
    const { id, ...other } = localStep;

    if (localStep.steps) {
      localStep.steps = serializeSteps(localStep.steps as Step[], flowId);
      return localStep;
    }

    const result: SerializedStep = {
      ...other,
      name: localStep.name,
      stepType: localStep.stepType,
      description: localStep.description ?? "",
      condition: localStep.condition ?? "",
      config: serializeStepConfig(localStep.config, flowId),
    };

    if (localStep.cases) {
      // @ts-expect-error: nested cases typing
      result.cases = serializeSteps(localStep.cases, flowId);
    }

    if (localStep.defaultCase) {
      result.defaultCase = serializeSteps(
        localStep.defaultCase as Step[],
        flowId
      );
    }

    if (result.virtualStepId && flowId && workspace) {
      updateUsage(
        { id: flowId, entityType: "flows" },
        { virtualSteps: [result.virtualStepId] },
        workspace
      );
    }

    return result;
  });
};

export const deserializeFlow = (
  id: string,
  fileFlow: SerializedFlow,
  workspace: Workspace,
  newFlowName?: string
): Flow => {
  const fileName = newFlowName || getFileDisplayName(id, workspace, "flows");

  updateUsage(
    { id, entityType: "flows" },
    { schemas: [fileFlow.sampleInputSchema] },
    workspace
  );
  addMetadata(id, { status: fileFlow.status });

  return {
    id,
    name: fileName!,
    status: fileFlow.status,
    description: fileFlow.description,
    steps: deserializeSteps(fileFlow.steps, id, workspace),
    tags: fileFlow.tags,
    sampleInputSchema: fileFlow.sampleInputSchema ?? "",
    sampleData: fileFlow.sampleData ?? {},
    resultPath: fileFlow.resultPath,
  };
};

export const serializeFlow = (
  flow: Flow,
  workspace?: Workspace
): SerializedFlow => {
  addMetadata(flow.id, { status: flow.status });

  const result: SerializedFlow = {
    tags: flow.tags,
    status: flow.status,
    sampleInputSchema: flow.sampleInputSchema ?? "",
    sampleData: flow.sampleData ?? {},
    description: flow.description ?? "",
    steps: serializeSteps(flow.steps, flow.id, workspace),
  };

  workspace &&
    updateUsage(
      { id: flow.id, entityType: "flows" },
      { schemas: [flow.sampleInputSchema] },
      workspace
    );

  if (flow.resultPath) {
    result.resultPath = flow.resultPath;
  }

  return result;
};

export const initFlowReferences = async (
  ids: string[],
  workspace: Workspace
): Promise<void> => {
  for (const id of ids) {
    await getFlow(id, workspace);
  }
};

export const createVirtualStepInstance = (
  stepId: string,
  virtualStep: VirtualStep,
  virtualStepSchema: Schema | undefined
): Step => {
  const virtualStepId = serializeId(virtualStep.id) ?? virtualStep.id;
  const { jsonSchema } = createVirtualStepInstanceSchema(
    virtualStep,
    virtualStepSchema
  );
  const step = utils.getDefaultFormState(jsonSchema as JSONSchema7, {
    id: stepId,
    virtualStepId,
    stepType: Steps.VIRTUAL,
  }) as Step;

  return step;
};

export const markFlowsForReview = async (
  flowIds: string[],
  workspace: Workspace
): Promise<void> => {
  for (const flowId of flowIds) {
    const flow = await getFlow(flowId, workspace);
    flow.status = "review-pending";
    await saveFlow(flow, workspace);
  }
};
