import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const mssqlDBStepSchema: StepSchema = {
  stepType: Steps.MSSQL_DB,
  color: "rgb(95, 206, 165)",
  jsonSchema: {
    description: "Connection to the sqldb",
    title: "MS SQL",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["connectionString", "actions"],
        properties: {
          user: {
            title: "User Name",
            type: "string",
          },
          password: {
            title: "Password",
            type: "string",
          },
          connectionString: {
            title:
              "A string to connect to MS SQL database Example: Server=localhost,1433;Database=database;User Id=username;Password=password;Encrypt=true",
            type: "string",
          },
          actions: {
            type: "array",
            title: "Actions",
            default: [
              {
                sql: "",
                binds: {},
              },
            ],
            items: {
              type: "object",
              required: ["sql"],
              properties: {
                sql: {
                  title: "SQL",
                  type: "string",
                },
                binds: {
                  title: "Binds",
                  type: ["object", "string"],
                  pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                },
                targetPath: {
                  title:
                    "Target path - target location of the source data inside the document",
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      password: { "ui:widget": "password" },
      actions: {
        items: {
          sql: {
            "ui:widget": "textarea",
          },
          binds: {
            "ui:field": "json",
          },
        },
      },
    },
  },
};
