type Plugin = {
  wrapComponents: {
    errors: () => () => null;
  };
};

export const HideAllErrorsPlugin = (): Plugin => {
  return {
    wrapComponents: {
      errors: () => () => null,
    },
  };
};
