import { DEFAULT_GIT_AUTHOR } from "@sapiens-digital/ace-designer-app/app/constant/uiConstants";
import { Settings } from "@sapiens-digital/ace-designer-app/app/model/settings";
import { WorkspaceSettings } from "@sapiens-digital/ace-designer-app/app/model/workspace";
import {
  GetEnvironmentVariables,
  LoadDesignerSettings,
  SaveDesignerSettings,
} from "@sapiens-digital/ace-designer-app/app/services/settings";
import { DesignerConfig } from "@sapiens-digital/ace-designer-common/lib/model/settings";

import { LoadWorkspaceSettings, SaveWorkspaceSettings } from "./workspace";

const ACE_DESIGNER_SETTINGS = "ACE_DESIGNER_SETTINGS";
const ACE_DESIGNER_WORKSPACES = "/";
const ACE_WORKSPACE_SETTINGS = "ACE_WORKSPACE_SETTINGS";

export const DEFAULT_SETTINGS: Settings = {
  workspacesLocation: ACE_DESIGNER_WORKSPACES,
  repositoryWorkspacePath: "/src/ace",
  repositoryDefaultBranch: "develop",
  fullName: DEFAULT_GIT_AUTHOR.name,
  email: DEFAULT_GIT_AUTHOR.email,
};

const DEFAULT_WORKSPACE_SETTINGS: WorkspaceSettings = {
  workspaces: [],
};

export const loadDesignerSettings: LoadDesignerSettings = async () => {
  const serialized = localStorage.getItem(ACE_DESIGNER_SETTINGS);
  if (serialized) {
    return { ...DEFAULT_SETTINGS, ...JSON.parse(serialized) };
  }

  await saveDesignerSettings(DEFAULT_SETTINGS);
  return DEFAULT_SETTINGS;
};

export const saveDesignerSettings: SaveDesignerSettings = async (settings) => {
  localStorage.setItem(ACE_DESIGNER_SETTINGS, JSON.stringify(settings));
};

export const loadWorkspaceSettings: LoadWorkspaceSettings = async () => {
  const serialized = localStorage.getItem(ACE_WORKSPACE_SETTINGS);
  if (serialized) return JSON.parse(serialized);

  await saveWorkspaceSettings(DEFAULT_WORKSPACE_SETTINGS);
  return DEFAULT_WORKSPACE_SETTINGS;
};

export const saveWorkspaceSettings: SaveWorkspaceSettings = async (
  settings
) => {
  localStorage.setItem(ACE_WORKSPACE_SETTINGS, JSON.stringify(settings));
};

export const getEnvVariables: GetEnvironmentVariables = async () => {
  const configRes = await fetch("/api/config");
  const config: DesignerConfig = await configRes.json();
  return {
    ...process.env,
    ...config,
  } as DesignerConfig;
};
