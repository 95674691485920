import React, { useEffect } from "react";

import {
  closeWindowAfterMovingTokenQuery,
  closeWindowAfterRemovingToken,
  isLogoutAction,
  isTokenInQuery,
} from "./utils";

export const LoginTracker: React.FC = () => {
  useEffect(() => {
    if (isTokenInQuery()) closeWindowAfterMovingTokenQuery();
    if (isLogoutAction()) closeWindowAfterRemovingToken();
  }, []);

  return null;
};
