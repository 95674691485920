import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AppEventName } from "@sapiens-digital/ace-designer-common";

import { Settings } from "../../model/settings";
import { emitAppEvent } from "../../services/appEvents";
import {
  loadDesignerSettings,
  saveDesignerSettings,
} from "../../services/settings";
import { SettingsManager } from "../../services/settingsManager";
import { hideLocalRepositoryWarning } from "../designer/actions";
import { resetDesignerPages } from "../designer/reducers";
import { RootState } from "../index";
import { areSettingsValid } from "../utils/areSettingsValid";
import { assertWorkspace } from "../utils/assertEntities";
import {
  deleteLocalWorkspacesAction,
  updateAndSetupWorkspaceAction,
} from "../workspaces/actions";
import {
  selectSelectedWorkspace,
  workspaceSelectors,
} from "../workspaces/selectors";

export const loadDesignerSettingsAction = createAsyncThunk(
  "settings/load",
  async () => {
    const settings = await loadDesignerSettings();
    SettingsManager.setDesignerSettings(settings);
    return settings;
  }
);

export const saveDesignerSettingsAction = createAsyncThunk<
  Settings,
  Settings,
  { state: RootState }
>("settings/save", async (newSettings: Settings, { getState, dispatch }) => {
  const { settings } = getState();
  const settingCopy = { ...newSettings };
  const currentWorkspace = selectSelectedWorkspace(getState());

  if (settings.repositoryUrl !== newSettings.repositoryUrl) {
    await dispatch(deleteLocalWorkspacesAction());
    settingCopy.selectedWorkspaceId = undefined;
  }

  if (areSettingsValid(newSettings)) {
    dispatch(hideLocalRepositoryWarning());
  }

  SettingsManager.setGitAuthor({
    name: newSettings.fullName,
    email: newSettings.email,
  });

  await saveDesignerSettings(settingCopy);

  emitAppEvent(AppEventName.UpdatedWorkspaceSettings, {
    workspaceLocation: currentWorkspace?.location || "",
    repositoryWorkspacePath: newSettings.repositoryWorkspacePath,
  });

  return settingCopy;
});

export const saveGitAuthorFromKeycloakAction = createAsyncThunk<
  Settings,
  { name: string | null; email: string | null },
  { state: RootState }
>(
  "settings/saveGitAuthorFromKeycloak",
  async ({ name, email }, { getState }) => {
    const { settings } = getState();
    const newState: Settings = {
      ...settings,
      isNameEmailFromKeycloak: true,
    };

    if (name) {
      newState.fullName = name;
    }

    if (email) {
      newState.email = email;
    }

    SettingsManager.setGitKeycloakAuthor({
      name: newState.fullName,
      email: newState.email,
    });

    return newState;
  }
);

const removeKeycloakNameEmail = async (settings: Settings) => {
  const settingsFromFile = await loadDesignerSettings();
  const newState = {
    ...settings,
    fullName: settingsFromFile.fullName,
    email: settingsFromFile.email,
    isNameEmailFromKeycloak: false,
  };

  return newState;
};

export const removeGitAuthorFromKeycloakAction = createAsyncThunk<
  Settings,
  void,
  { state: RootState }
>("settings/removeGitAuthorFromKeycloakAction", async (_, { getState }) => {
  SettingsManager.resetGitKeycloakAuthor();
  const { settings } = getState();
  return removeKeycloakNameEmail(settings);
});

export const switchWorkspaceAction = createAsyncThunk(
  "settings/switchWorkspace",
  async (workspaceId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const settings = { ...state.settings, selectedWorkspaceId: workspaceId };

    const workspace = workspaceSelectors.selectById(state, workspaceId);
    assertWorkspace(workspace, workspaceId);

    dispatch(selectWorkspaceAction(workspaceId));
    dispatch(resetDesignerPages());

    if (settings.isNameEmailFromKeycloak) {
      await saveDesignerSettings(await removeKeycloakNameEmail(settings));
    } else {
      await saveDesignerSettings(settings);
    }

    if (areSettingsValid(settings)) {
      await dispatch(
        updateAndSetupWorkspaceAction({
          ...settings,
          workspaceName: workspace.name,
        })
      ).unwrap();
    }

    emitAppEvent(AppEventName.UpdatedWorkspaceSettings, {
      workspaceLocation: workspace.location,
      repositoryWorkspacePath: workspace.repositoryWorkspacePath,
    });
  }
);

export const selectWorkspaceAction = createAction<string>(
  "settings/selectWorkspace"
);
