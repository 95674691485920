import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const codeStepSchema: StepSchema = {
  stepType: Steps.CODE,
  color: "rgb(223,204,241)",
  jsonSchema: {
    title: "JS Code Functions",
    description: "Execute Javascript code",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step description",
      },
      condition: {
        type: "string",
        description:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        title: "Condition",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["codeBlock", "targetPath"],
        properties: {
          codeBlock: {
            type: "string",
            title: "Code block",
            description: "Code block to be executed",
            minLength: 1,
          },
          targetPath: {
            type: "string",
            title: "Target path",
            description: "Path to place the result of the executed code",
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      codeBlock: {
        "ui:field": "js",
      },
    },
  },
};
