import {
  DevelopmentRequestMetadata,
  HEADER_ENCODED_HEADERS,
} from "../model/aceHeaders";
import { EncodedHeaders } from "./aceHeaders";

export const getBase64EncodedHeaders = (
  headers: Record<string, unknown>
): EncodedHeaders => {
  try {
    return {
      [HEADER_ENCODED_HEADERS]: Buffer.from(JSON.stringify(headers)).toString(
        "base64"
      ),
    };
  } catch (err) {
    throw new Error(`Error in encoding headers`);
  }
};

export const getBase64DecodedHeaders = (
  base64Value: string,
  base64Key: string
): DevelopmentRequestMetadata => {
  if (!base64Value) {
    console.error(`Invalid value for ${base64Key}: ${base64Value}`);
    throw new Error(`Invalid value for ${base64Key}: ${base64Value}`);
  }
  try {
    return JSON.parse(Buffer.from(base64Value, "base64").toString());
  } catch (err) {
    throw new Error(
      `Error in decoding ${base64Key}: ${
        (err as Record<"message", string>).message
      }`
    );
  }
};
