import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { WorkspaceDetails } from "@sapiens-digital/ace-designer-common/lib/model/git";
import {
  ALL_WORKSPACE_PATHS,
  WORKSPACE_PATHS,
} from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import { saveAs } from "file-saver";
import { HttpClient, PromiseFsClient } from "isomorphic-git";
import { v4 as uuidv4 } from "uuid";

import { FileNode } from "../../model/file";
import {
  ExecuteFlowApiResult,
  ExecuteFlowOptions,
  ExecuteFlowResult,
} from "../../model/flowExecution";
import {
  LocalWorkspace,
  Remote,
  Workspace,
  WorkspaceFolder,
  WorkspaceVersion,
} from "../../model/workspace";
import { readError } from "../../store/utils/readError";
import {
  cleanDirectory,
  copyFilesDeep,
  exists,
  isDir,
  mkdir,
  readYaml,
  rmdirDeep,
} from "../fs-utils";
import {
  getRemoteInfo,
  gitClone,
  gitPush,
  initializeGitRepository,
  synchronizeWorkspaceWithRemote,
} from "../git-utils";
import { loadNodes } from "../nodes";
import { deserializeId, upsertDeserializedId } from "../references";
import { SettingsManager } from "../settingsManager";
import { createTemporaryFolder } from "../temporaryFiles";
import { container, symbols } from "../";

import { importApiFiles } from "./import/importApis";
import { importWorkspaceVariables } from "./import/importWorkspaceVariables";
import {
  migrateV1EntitiesToV2,
  MigrationError,
  V1Folder,
} from "./migrate/migrate";
import { downloadFS } from "./downloadFs";
import {
  loadWorkspaceSettings,
  saveWorkspaceSettings,
} from "./workspaceSettings";

export enum Environment {
  Electron,
  Web,
}

const folderPathMap: Record<WorkspaceFolder, string> = {
  apis: WORKSPACE_PATHS.APIS,
  flows: WORKSPACE_PATHS.FLOWS,
  schedules: WORKSPACE_PATHS.SCHEDULES,
  schemas: WORKSPACE_PATHS.API_SCHEMAS,
  virtualSteps: WORKSPACE_PATHS.VIRTUAL_STEPS,
  modelFields: WORKSPACE_PATHS.API_FIELDS,
  modelHeaders: WORKSPACE_PATHS.API_HEADERS,
  modelParameters: WORKSPACE_PATHS.API_PARAMETERS,
  modelResponses: WORKSPACE_PATHS.API_RESPONSES,
  variables: WORKSPACE_PATHS.VARIABLES,
  errorHandlers: WORKSPACE_PATHS.ERROR_HANDLERS,
};

export interface GetWorkspaceFS {
  (): PromiseFsClient;
}

export interface GetWorkspaceHttpClient {
  (): HttpClient;
}

export interface GetEnvironment {
  (): Environment;
}

export interface ExecuteFlow {
  (config: ExecuteFlowOptions, deploymentDetails?: WorkspaceDetails): Promise<
    ExecuteFlowResult | ExecuteFlowApiResult
  >;
}

export const getWorkspaceFS: GetWorkspaceFS = () =>
  container.get<GetWorkspaceFS>(symbols.GetWorkspaceFS)();

export const getEnvironment: GetEnvironment = () =>
  container.get<GetEnvironment>(symbols.GetEnvironment)();

export const getWorkspaceHttpClient: GetWorkspaceHttpClient = () =>
  container.get<GetWorkspaceHttpClient>(symbols.GetWorkspaceHttpClient)();

export const executeFlow: ExecuteFlow = async (config, deploymentDetails) =>
  container.get<ExecuteFlow>(symbols.ExecuteFlow)(config, deploymentDetails);

export const ERROR_INVALID_CONTENT = "INVALID_CONTENT";

function getDefaultWorkspace({
  workspaceName,
  workspaceLocation,
  repositoryWorkspacePath,
  version,
}: {
  workspaceName: string;
  workspaceLocation: string;
  repositoryWorkspacePath: string;
  version: WorkspaceVersion;
}): Workspace {
  return {
    id: deserializeId("/", workspaceName),
    name: workspaceName,
    location: workspaceLocation,
    repositoryWorkspacePath: repositoryWorkspacePath,
    flowIds: [],
    flows: [],
    apis: [],
    apiIds: [],
    schedules: [],
    schemas: [],
    virtualSteps: [],
    modelFields: [],
    modelHeaders: [],
    modelParameters: [],
    modelResponses: [],
    variables: [],
    errorHandlers: [],
    version,
  };
}

const initializeWorkspace = async (
  workspaceName: string,
  workspaceLocation: string,
  repositoryWorkspacePath: string,
  version: WorkspaceVersion
): Promise<void> => {
  const workspaceRoot = posixPath.join(
    workspaceLocation,
    repositoryWorkspacePath
  );

  await mkdir(workspaceRoot);
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.FLOWS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.APIS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_SCHEMAS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_FIELDS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_HEADERS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_PARAMETERS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_RESPONSES));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.SCHEDULES));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.API_SCHEMAS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.ERROR_HANDLERS));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.VARIABLES));
  await mkdir(posixPath.join(workspaceRoot, WORKSPACE_PATHS.VIRTUAL_STEPS));
};

export const createWorkspace = async (
  workspaceName: string,
  repositoryWorkspacePath: string,
  repositoryUrl?: string,
  repositoryToken?: string,
  repositoryDefaultBranch?: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  if (await exists(workspaceLocation)) {
    throw Error(`Directory ${workspaceLocation} already exists.`);
  }

  await mkdir(workspaceLocation);

  await initializeGitRepository({
    repositoryToken,
    workspaceName,
    repositoryUrl,
    workspaceLocation,
    repositoryDefaultBranch,
    repositoryUsername,
  });

  await initializeWorkspace(
    workspaceName,
    workspaceLocation,
    repositoryWorkspacePath,
    WorkspaceVersion.V2
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryWorkspacePath,
    WorkspaceVersion.V2,
    workspaceName
  );
};

export function getWorkspaceLocation(workspaceName: string): string {
  return posixPath.join(SettingsManager.getWorkspacesLocation(), workspaceName);
}

/**
 * Retrieve workspace from git and read it
 * @param workspaceName
 * @param repositoryWorkspacePath
 * @param repositoryUrl
 * @param repositoryToken
 * @param repositoryUsername
 */
export const openWorkspace = async (
  workspaceName: string,
  repositoryWorkspacePath: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  if (await exists(workspaceLocation)) {
    throw Error(`Directory ${workspaceLocation} already exists.`);
  }

  await mkdir(workspaceLocation);
  await gitClone(
    repositoryToken,
    {
      dir: workspaceLocation,
      ref: workspaceName,
      url: repositoryUrl,
    },
    repositoryUsername
  );

  const version = WorkspaceVersion.V2;

  await initializeWorkspace(
    workspaceName,
    workspaceLocation,
    repositoryWorkspacePath,
    version
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryWorkspacePath,
    version,
    workspaceName
  );
};

/**
 * Retrieve workspace from git and read it
 * @param workspaceName
 * @param repositoryWorkspacePath
 * @param repositoryUrl
 * @param repositoryToken
 * @param repositoryUsername
 */
export const updateWorkspace = async (
  workspaceName: string,
  repositoryWorkspacePath: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  await synchronizeWorkspaceWithRemote(
    workspaceName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryWorkspacePath,
    WorkspaceVersion.V2,
    workspaceName
  );
};

export const updateFolder = async (
  workspace: Workspace,
  folder: WorkspaceFolder,
  filesOnly?: boolean
): Promise<Array<FileNode>> => {
  const root = getContentRoot(workspace, folder);
  const location = getDirPath(workspace, folder);
  return loadNodes(root, location, filesOnly);
};

export const getContentRoot = (
  workspace: Pick<Workspace, "location">,
  folder: WorkspaceFolder
): string => posixPath.join(workspace.location, getFolderPath(folder));

async function loadOrGenerateWorkspaceSettings(
  workspaceName: string
): Promise<LocalWorkspace> {
  const settings = await loadWorkspaceSettings();
  const wsSettings = settings.workspaces.find(
    ({ folderName }) => folderName === workspaceName
  );

  if (!wsSettings) {
    const id = deserializeId("/", workspaceName);
    const newSettings = { id, folderName: workspaceName };
    await saveWorkspaceSettings({
      workspaces: [...settings.workspaces, newSettings],
    });
    return newSettings;
  }

  return wsSettings;
}

const WORKSPACE_DEFAULT_NAME = "UNKNOWN";

export const loadWorkspace = async (
  location: string,
  repositoryWorkspacePath: string,
  version: WorkspaceVersion.V2 | WorkspaceVersion.UNKNOWN,
  workspaceName: string = WORKSPACE_DEFAULT_NAME
): Promise<Workspace> => {
  const { id } = await loadOrGenerateWorkspaceSettings(workspaceName);
  upsertDeserializedId(id, "/", workspaceName);

  const workspace = getDefaultWorkspace({
    workspaceName,
    workspaceLocation: location,
    repositoryWorkspacePath,
    version,
  });

  if (WorkspaceVersion.V2 !== version) {
    return workspace;
  }

  return {
    id,
    name: workspace.name,
    location,
    repositoryWorkspacePath: workspace.repositoryWorkspacePath,
    selectedFlowId: workspace.selectedFlowId,
    flowIds: workspace.flowIds,
    apiIds: workspace.apiIds,
    version,
    flows: await loadNodes(
      getContentRoot(workspace, "flows"),
      posixPath.join(location, repositoryWorkspacePath, WORKSPACE_PATHS.FLOWS)
    ),
    apis: await loadNodes(
      getContentRoot(workspace, "apis"),
      posixPath.join(location, repositoryWorkspacePath, WORKSPACE_PATHS.APIS),
      true
    ),
    schedules: await loadNodes(
      getContentRoot(workspace, "schedules"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.SCHEDULES
      )
    ),
    schemas: await loadNodes(
      getContentRoot(workspace, "schemas"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.API_SCHEMAS
      )
    ),
    virtualSteps: await loadNodes(
      getContentRoot(workspace, "virtualSteps"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.VIRTUAL_STEPS
      )
    ),
    modelFields: await loadNodes(
      getContentRoot(workspace, "modelFields"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.API_FIELDS
      )
    ),
    modelHeaders: await loadNodes(
      getContentRoot(workspace, "modelHeaders"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.API_HEADERS
      )
    ),
    modelParameters: await loadNodes(
      getContentRoot(workspace, "modelParameters"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.API_PARAMETERS
      )
    ),
    modelResponses: await loadNodes(
      getContentRoot(workspace, "modelResponses"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.API_RESPONSES
      )
    ),
    variables: await loadNodes(
      getContentRoot(workspace, "variables"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.VARIABLES
      )
    ),
    errorHandlers: await loadNodes(
      getContentRoot(workspace, "errorHandlers"),
      posixPath.join(
        location,
        repositoryWorkspacePath,
        WORKSPACE_PATHS.ERROR_HANDLERS
      )
    ),
  };
};

const isOrContainsYamlFile = async (
  filePath: string,
  fs: PromiseFsClient
): Promise<boolean> => {
  if (await isDir(filePath)) {
    const files: string[] = await fs.promises.readdir(filePath);

    for (const file of files) {
      if (await isOrContainsYamlFile(posixPath.join(filePath, file), fs))
        return true;
    }

    return false;
  } else {
    return filePath.toLocaleLowerCase().endsWith(".yaml");
  }
};

const isV2Workspace = async (location: string): Promise<boolean> => {
  const fs = getWorkspaceFS();
  return await isOrContainsYamlFile(location, fs);
};

/**
 *
 * @param workspaceRoot
 * @returns
 */
export const validateWorkspace = async (
  workspaceRoot: string
): Promise<WorkspaceVersion.V2 | WorkspaceVersion.UNKNOWN> => {
  if ((await isDir(workspaceRoot)) && (await isV2Workspace(workspaceRoot))) {
    return WorkspaceVersion.V2;
  }

  return WorkspaceVersion.UNKNOWN;
};

export const loadWorkspaces = async (
  repositoryWorkspacePath: string
): Promise<Array<Workspace>> => {
  const workspacesLocation = SettingsManager.getWorkspacesLocation();
  const fs = getWorkspaceFS();
  const result: Array<Workspace> = [];

  if (
    !(await exists(workspacesLocation)) ||
    !(await isDir(workspacesLocation))
  ) {
    return result;
  }

  const allFiles: string[] = await fs.promises.readdir(
    posixPath.join(workspacesLocation)
  );

  const hiddenFolderPrefix = ".";
  const omitHiddenFolders = (file: string) =>
    !posixPath.basename(file).startsWith(hiddenFolderPrefix);
  const files = allFiles.filter(omitHiddenFolders);

  // TODO: do not load all files for all workspaces
  for (const file of files) {
    const location = posixPath.join(workspacesLocation, file);
    let version = WorkspaceVersion.UNKNOWN;

    if (!(await isDir(location))) {
      version = WorkspaceVersion.INVALID;
    }

    if (WorkspaceVersion.INVALID !== version) {
      if (
        await isDir(
          posixPath.join(workspacesLocation, file, repositoryWorkspacePath)
        )
      ) {
        version = WorkspaceVersion.V2;
      }

      result.push(
        await loadWorkspace(
          posixPath.join(workspacesLocation, file),
          repositoryWorkspacePath,
          version,
          file
        )
      );
    }
  }

  await removeObsoleteWorkspaceSettings(result);
  return result;
};

const removeObsoleteWorkspaceSettings = async (
  workspaces: Workspace[]
): Promise<void> => {
  const loadedWorkspaces = workspaces.map(({ name }) => name);
  const { workspaces: currentSettings } = await loadWorkspaceSettings();
  const omitNonLocalWorkspaces = currentSettings.filter((ws) =>
    loadedWorkspaces.includes(ws.folderName)
  );
  await saveWorkspaceSettings({ workspaces: omitNonLocalWorkspaces });
};

export const loadRemotes = async (
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Array<Remote>> => {
  const data = await getRemoteInfo(
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  if (data.refs === undefined || data.refs.heads === undefined) {
    return [];
  }

  return Object.keys(data.refs.heads).map((item) => ({
    id: item,
    name: item,
  }));
};

const rmdir = async (location: string) => {
  const fs = getWorkspaceFS();

  if (await isDir(location)) {
    const nodes = (await fs.promises.readdir(location)) as Array<string>;
    const promises = nodes.map((node) => {
      const newLocation = posixPath.join(location, node);
      return rmdir(newLocation);
    });
    await Promise.all(promises);
    await fs.promises.rmdir(location);
  } else {
    await fs.promises.unlink(location);
  }
};

export const deleteWorkspace = async (location: string): Promise<void> => {
  await rmdir(location);
};

const cloneWorkspace = async (
  repositoryUrl: string,
  repositoryToken: string,
  location: string,
  workspaceName: string,
  repositoryUsername?: string
): Promise<boolean> => {
  try {
    await gitClone(
      repositoryToken,
      {
        dir: location,
        ref: workspaceName,
        url: repositoryUrl,
      },
      repositoryUsername
    );
    return true;
  } catch (err) {
    console.error(
      `Failed to create temp clone. Error: ${readError(err, "message")}`
    );
    return false;
  }
};

export const deleteRemote = async (
  workspace: Remote,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> => {
  const workspaceLocation = getWorkspaceLocation(workspace.name);

  let isTempCloneCreated = false;
  const tempLocation = "/" + uuidv4();

  // TODO: check
  // temp clone branch because git.push needs config file to read
  if (!(await isDir(workspaceLocation))) {
    isTempCloneCreated = await cloneWorkspace(
      repositoryUrl,
      repositoryToken,
      tempLocation,
      workspace.name,
      repositoryUsername
    );
  }

  const result = await gitPush(
    repositoryToken,
    {
      dir: isTempCloneCreated ? tempLocation : workspaceLocation,
      ref: workspace.name,
      delete: true,
      url: repositoryUrl,
    },
    repositoryUsername
  );

  if (isTempCloneCreated) {
    await rmdir(tempLocation);
  }

  if (!result.ok) {
    throw Error(`Failed to delete remote branch. Error: ${result.error}`);
  }
};

const tryCleanV1Workspace = async (
  workspaceLocation: string
): Promise<void> => {
  try {
    await cleanDirectory(workspaceLocation, Object.values(V1Folder));
  } catch (e) {
    console.warn(`Failed clearing V1 workspace`, e);
    throw new Error(ERROR_INVALID_CONTENT);
  }
};

const tryCleanV2Workspace = async (
  workspaceLocation: string
): Promise<void> => {
  try {
    await cleanDirectory(workspaceLocation, Object.values(folderPathMap));
  } catch (e) {
    console.warn(`Failed clearing v2 workspace`, e);
    throw new Error(ERROR_INVALID_CONTENT);
  }
};

const isWorkspaceEmpty = async (
  workspaceLocation: string
): Promise<boolean> => {
  const fs = getWorkspaceFS();
  const wsDirs = await fs.promises.readdir(workspaceLocation);
  return wsDirs.length === 0;
};

const assertWorkspaceNotEmpty = async (
  workspaceLocation: string
): Promise<void> => {
  if (await isWorkspaceEmpty(workspaceLocation)) {
    console.warn("workspace is empty after cleanup");
    throw new Error(ERROR_INVALID_CONTENT);
  }
};

const importWorkspaceV2 = async (
  sourceRepositoryLocation: string,
  repositoryLocation: string
) => {
  const copyFolders = ALL_WORKSPACE_PATHS.filter(
    (f) =>
      ![
        String(WORKSPACE_PATHS.APIS),
        String(WORKSPACE_PATHS.VARIABLES),
      ].includes(f)
  ).map(async (folder) => {
    const sourceFolderPath = posixPath.join(sourceRepositoryLocation, folder);

    if (await isDir(sourceFolderPath)) {
      return copyFilesDeep(
        sourceFolderPath,
        posixPath.join(repositoryLocation, folder),
        ".yaml"
      );
    }
  });

  await Promise.all(copyFolders);
  await Promise.all([
    importWorkspaceVariables(sourceRepositoryLocation, repositoryLocation),
    importApiFiles(sourceRepositoryLocation, repositoryLocation),
  ]);
};

export const importWorkspace = async (
  workspaceLocation: string,
  repositoryWorkspacePath: string,
  sourceWorkspaceLocation: string,
  overwriteExisting?: boolean
): Promise<{ errors: MigrationError[] }> => {
  const workspacesLocation = SettingsManager.getWorkspacesLocation();
  const version = await validateWorkspace(sourceWorkspaceLocation);

  let sourceRepositoryLocation = sourceWorkspaceLocation;

  const repositoryLocation = posixPath.join(
    workspaceLocation,
    repositoryWorkspacePath
  );

  const errors: MigrationError[] = [];
  const needsMigration = version !== WorkspaceVersion.V2;

  if (needsMigration) {
    sourceRepositoryLocation = posixPath.join(
      await createTemporaryFolder(workspacesLocation),
      repositoryWorkspacePath
    );

    if (!(await isWorkspaceEmpty(sourceWorkspaceLocation))) {
      await tryCleanV1Workspace(sourceWorkspaceLocation);
      await assertWorkspaceNotEmpty(sourceWorkspaceLocation);
    }

    // for now, treat NON-V2 as V1
    const migrationErrors = await migrateV1EntitiesToV2(
      sourceWorkspaceLocation,
      sourceRepositoryLocation,
      repositoryLocation,
      overwriteExisting
    );

    if (migrationErrors.length) {
      errors.push(...migrationErrors);
      console.error(
        `Encountered ${migrationErrors.length} errors during migration:`
      );
      console.error(...migrationErrors);
    }
  } else {
    await tryCleanV2Workspace(sourceWorkspaceLocation);
    await assertWorkspaceNotEmpty(sourceWorkspaceLocation);
  }

  if (overwriteExisting) {
    await rmdirDeep(repositoryLocation, { skipLocationRoot: true });
  }

  if (needsMigration || overwriteExisting) {
    await copyFilesDeep(sourceRepositoryLocation, repositoryLocation, ".yaml");
  } else {
    await importWorkspaceV2(sourceRepositoryLocation, repositoryLocation);
  }

  return { errors };
};

/**
 * Retrieves one of workspace folder's path
 * @returns one of workspace folder's path (file system path)
 */
export const getDirPath = (
  workspace: Workspace,
  folder: WorkspaceFolder
): string =>
  posixPath.join(
    workspace.location,
    workspace.repositoryWorkspacePath,
    getFolderPath(folder)
  );

export const getFolderPath = (folder: WorkspaceFolder): string => {
  const folderPath = folderPathMap[folder];

  if (folderPath === undefined) {
    throw new Error(`Can not get folder path for ${folder}`);
  }

  return folderPath;
};

/**
 * Reads file from the workspace under specific dir
 */
export const readFileFromWorksapce = (
  workspace: Workspace,
  folder: WorkspaceFolder,
  filePath: string
): unknown => {
  const dir = getDirPath(workspace, folder);
  const absPath = posixPath.join(dir, filePath);
  return readYaml(absPath);
};

export const downloadWorkspaceAsZip = async (
  rootDir: string,
  workspaceName: string,
  repoWorkspacePath = "src/ace"
): Promise<void> => {
  const srcPath = posixPath.join(rootDir, workspaceName, repoWorkspacePath);
  const blob = new Blob([await downloadFS(srcPath)], {
    type: "application/zip",
  });
  saveAs(blob, `${workspaceName}.zip`);
};
