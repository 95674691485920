import { createSelector } from "@reduxjs/toolkit";

import { VirtualStep } from "../../model";
import { FlowUi, SchedulerUi, StepUi } from "../../model/designer";
import { FieldError } from "../../model/formError";
import { RootState } from "../index";
import { selectEntity } from "../utils/redoableSliceSelectors";

import {
  selectSchemasSelectedItemId,
  selectVirtualStepsSelectedItemId,
} from "./pageSelectors";

export const selectSelectedFlowStep = (
  state: RootState,
  flowId: string
): string | undefined => state.designer.selectedStepIds[flowId];

export const selectSelectedEntityValidations = (
  state: RootState,
  id: string
): Array<FieldError> | undefined => {
  if (state.designer.entityValidationErrors) {
    return state.designer.entityValidationErrors[id];
  }

  return undefined;
};

export const selectFlowUi = (
  state: RootState,
  flowId: string
): FlowUi | undefined => state.designer.flowUi[flowId];

export const selectStepUi = (
  state: RootState,
  stepId: string
): StepUi | undefined => state.designer.stepUi[stepId];

export const selectSelectedVirtualStep = (
  state: RootState
): VirtualStep | undefined => {
  const entity = state.virtualSteps.find(
    (virtualStep) =>
      virtualStep.present.id === selectVirtualStepsSelectedItemId(state)
  );

  if (entity) {
    return entity.present;
  }
};

export const selectSelectedTab = (state: RootState, flowId: string): number =>
  state.designer.flowUi[flowId]?.selectedTab ?? 0;

export const selectIsDesignerLoading = (state: RootState): boolean =>
  state.designer.isDesignerLoading;

export const selectSchedulerUi = (
  state: RootState,
  schedulerId: string
): SchedulerUi | undefined => state.designer.schedulerUi[schedulerId];

export const selectIsDesignerIndexing = (
  state: RootState
): boolean | undefined => state.designer.isIndexing;

export const selectUsername = (state: RootState): undefined | string | null =>
  state.designer.username;

export const selectSelectedSchema = createSelector(
  (state: RootState) => state.schemas,
  selectSchemasSelectedItemId,
  (schemas, id) => selectEntity(schemas, id)
);
