import { WorkspaceDetails } from "../model/git";
import { HEADER_ENCODED_HEADERS } from "../model/aceHeaders";
import { getBase64EncodedHeaders } from "./getbase64EncodedOrDecodedHeaders";

export type EncodedHeaders = { [HEADER_ENCODED_HEADERS]: string };

export const createAceHeaders = (
  deploymentDetails: WorkspaceDetails,
  authorizationToken: string
): EncodedHeaders => {
  return getBase64EncodedHeaders({
    repository: deploymentDetails.repository,
    token: deploymentDetails.token,
    branch: deploymentDetails.branch,
    authorization: authorizationToken,
    commit: deploymentDetails.commit,
    username: deploymentDetails.username,
    repositoryWorkspacePath: deploymentDetails.repositoryWorkspacePath,
  });
};
