import { DesignerConfig } from "@sapiens-digital/ace-designer-common/lib/model/settings";
import { DEFAULT_GIT_AUTHOR } from "constant/uiConstants";

import { RUNTIME_SERVER_DEFAULT_PORT } from "../constant/serverConfig";
import { Settings } from "../model/settings";

import { getEnvironmentVariables } from "./settings";
import { Environment, getEnvironment } from "./workspace";

export interface GitAuthor {
  name: string;
  email: string;
}

/**
 * This class saves settings which are required to be accessed globally.
 * 'designerSettings' will not be updated if changes are done in UI.
 * This is done on purpose so that some settings like runtime port are applied after next restart.
 */

export const DESIGNER_SETTINGS_KEY = "DESIGNER_SETTINGS";

export class SettingsManager {
  private static designerSettings: Settings;
  private static designerConfig: DesignerConfig;
  private static gitAuthor: GitAuthor | null;
  private static gitAuthorKeyCloak: GitAuthor | null;

  public static setDesignerSettings(initSettings: Settings): void {
    if (initSettings.fullName && initSettings.email) {
      this.setGitAuthor({
        name: initSettings.fullName,
        email: initSettings.email,
      });
    } else this.resetGitAuthor();

    if (this.designerSettings) {
      return;
    }

    this.designerSettings = initSettings;

    // If settings are present in session storage then use it.
    const settings = sessionStorage.getItem(DESIGNER_SETTINGS_KEY);

    if (settings) {
      try {
        this.designerSettings = JSON.parse(settings);
      } catch (err) {
        sessionStorage.setItem(
          DESIGNER_SETTINGS_KEY,
          JSON.stringify(this.designerSettings)
        );
      }
    } else {
      sessionStorage.setItem(
        DESIGNER_SETTINGS_KEY,
        JSON.stringify(this.designerSettings)
      );
    }
  }

  public static async init(): Promise<void> {
    this.designerConfig = await getEnvironmentVariables();
  }

  public static getDesignerSettings(): Settings {
    return this.designerSettings;
  }

  public static getSwaggerServerUrl(): string | undefined {
    const isElectron = getEnvironment() === Environment.Electron;

    if (isElectron) {
      const port =
        this.designerSettings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT;
      return `http://localhost:${port}`;
    }

    if (this.designerConfig.devApiBaseUrl) {
      return `${this.designerConfig.devApiBaseUrl}/api`;
    }
  }

  public static constructApiDefinitionUrl(apiName: string): string | undefined {
    if (this.designerConfig.devApiBaseUrl) {
      const trimmedBaseUrl = this.trimBaseUrl(
        this.designerConfig.devApiBaseUrl
      );
      return `#/api-definition?url=${trimmedBaseUrl}api-spec?api=${apiName}`;
    }
  }

  public static getWorkspacesLocation(): string {
    return this.designerSettings.workspacesLocation;
  }

  public static getIsDeprecatedStepsEnabled(): boolean {
    return this.designerConfig.enableDeprecatedSteps;
  }

  private static trimBaseUrl(baseUrl: string): string {
    return baseUrl.replace(/^((\w+:)?\/\/[^/]+\/?).*$/, "$1");
  }

  public static setGitAuthor(author: GitAuthor): void {
    this.gitAuthor = author;
  }

  public static setGitKeycloakAuthor(author: GitAuthor): void {
    this.gitAuthorKeyCloak = author;
  }

  public static resetGitAuthor(): void {
    this.gitAuthor = null;
  }

  public static resetGitKeycloakAuthor(): void {
    this.gitAuthorKeyCloak = null;
  }

  public static getGitAuthor(): GitAuthor {
    return this.gitAuthorKeyCloak || this.gitAuthor || DEFAULT_GIT_AUTHOR;
  }

  public static getDesignerConfigs(): DesignerConfig {
    return this.designerConfig;
  }
}
