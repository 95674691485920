import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isRejected } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  TextField,
} from "@mui/material";

import { Settings } from "../../model/settings";
import { RootState, useAppDispatch } from "../../store";
import { addNotification } from "../../store/designer/actions";
import {
  closeWorkspaceManager,
  setDesignerLoading,
} from "../../store/designer/reducers";
import { switchWorkspaceAction } from "../../store/settings/actions";
import { createWorkspaceAction } from "../../store/workspaces/actions";

export const WorkspaceManagerCreate: React.FC = () => {
  const MIN_WORKSPACE_NAME_LENGTH = 3;
  const dispatch = useAppDispatch();

  const settings = useSelector<RootState, Settings>((state) => state.settings);

  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceNameHelperText, setWorkspaceNameHelperText] = useState(
    `This will create a new workspace from remote "${settings.repositoryDefaultBranch}" branch.`
  );
  const [workspaceNameError, setWorkspaceNameError] = useState(false);

  const [inProgress, setInProgress] = useState(false);

  const showError = (message: string) => {
    dispatch(
      addNotification({
        message: message,
        variant: "error",
      })
    );
    setInProgress(false);
  };

  const create = async () => {
    if (workspaceName.trim().length < MIN_WORKSPACE_NAME_LENGTH) {
      setWorkspaceNameHelperText(
        "Workspace name must be at least 3 characters long."
      );
      setWorkspaceNameError(true);
      return;
    }

    setWorkspaceNameError(false);
    setWorkspaceNameHelperText("");

    setInProgress(true);
    const createResult = await dispatch(
      createWorkspaceAction({ ...settings, workspaceName: workspaceName })
    );

    if (isRejected(createResult)) {
      showError(
        createResult.error.message ??
          "Unexpected error during workspace creation"
      );
      return;
    }

    const switchResult = await dispatch(
      switchWorkspaceAction(createResult.payload.id)
    );

    if (isRejected(switchResult)) {
      showError(
        switchResult.error.message ?? "Unexpected error during workspace load"
      );
      return;
    }

    setInProgress(false);
    dispatch(closeWorkspaceManager());
  };

  return (
    <>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          label="Workspace name"
          value={workspaceName}
          onChange={(event) => setWorkspaceName(event.target.value)}
          helperText={workspaceNameHelperText}
          error={workspaceNameError}
        />
        {inProgress && (
          <Box py="10px">
            <LinearProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeWorkspaceManager());
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            dispatch(setDesignerLoading(true));
            await create();
            dispatch(setDesignerLoading(false));
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkspaceManagerCreate;
