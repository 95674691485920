import { FC } from "react";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import LayoutLoader from "@sapiens-digital/ace-designer-app/app/components/LayoutLoader";
import { API_DEFINITION_PATH } from "views/api-definition/APIDefinition";

import { ApiSpecEntity } from "./APIsList";

interface ListEntryProps {
  entry: ApiSpecEntity;
  specUrl: string;
}

const ListEntry: FC<ListEntryProps> = ({ entry, specUrl }) => {
  const actualUrl = `#${API_DEFINITION_PATH}?url=${specUrl}?api=${entry.name}`;

  return (
    <ListItem
      secondaryAction={
        <Button variant="outlined" href={actualUrl}>
          View definition
        </Button>
      }
    >
      <ListItemText primary={entry.name} />
    </ListItem>
  );
};

interface AvailableAPIsListProps {
  isLoading: boolean;
  apis: ApiSpecEntity[];
  specUrl: string;
  loadError: string;
}

const AvailableAPIsList: FC<AvailableAPIsListProps> = ({
  isLoading,
  apis,
  specUrl,
  loadError,
}) => {
  if (isLoading) {
    return (
      <Box sx={{ padding: "1rem" }}>
        <LayoutLoader />
      </Box>
    );
  }

  if (loadError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "1rem" }}
      >
        {loadError}
      </Box>
    );
  }

  return (
    <List>
      {apis.map((api) => (
        <ListEntry key={api.name} entry={api} specUrl={specUrl} />
      ))}
    </List>
  );
};

export { AvailableAPIsList };
